const Setting = ({ label, name, type = 'text', localSettings, settingsSubGroup, handleInputChange, disabled }) => {
	return (
		<div className="flex flex-col">
			<label className="block text-black dark:text-white">{label}:</label>
			{type === 'textarea' ? (
				<textarea
					name={name}
					value={localSettings?.[settingsSubGroup]?.[name] || ''}
					onChange={e => handleInputChange(e, settingsSubGroup)}
					disabled={disabled}
					className={`w-full rounded-lg p-2 h-24 resize-none ${
						disabled
							? 'text-slate-700 bg-gray-200 dark:bg-slate-600 dark:text-slate-200'
							: 'bg-none border text-black dark:bg-slate-700 dark:text-white'
					}`}
				/>
			) : type === 'checkbox' ? (
				<div className="flex items-center">
					<label className="relative inline-flex items-center cursor-pointer">
						<input
							type="checkbox"
							name={name}
							checked={localSettings?.[settingsSubGroup]?.[name] || false}
							onChange={e => handleInputChange(e, settingsSubGroup)}
							disabled={disabled}
							className="sr-only peer"
						/>
						<div
							className={`w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full 
            peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
            after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all 
            dark:border-gray-600 peer-checked:bg-blue-600`}
						></div>
					</label>
					<span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
						{localSettings?.[settingsSubGroup]?.[name] ? 'Enabled' : 'Disabled'}
					</span>
				</div>
			) : (
				<input
					type={type}
					name={name}
					value={localSettings?.[settingsSubGroup]?.[name] || ''}
					onChange={e => handleInputChange(e, settingsSubGroup)}
					disabled={disabled}
					className={`max-w-full rounded-lg p-2 ${
						disabled
							? 'text-slate-700 bg-gray-200 dark:bg-slate-600 dark:text-slate-200'
							: 'bg-none border text-black dark:bg-slate-700 dark:text-white'
					}`}
				/>
			)}
		</div>
	);
};

export default Setting;
