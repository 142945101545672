/* eslint-disable no-unused-vars */
import React from 'react';
import SOrderNCE from '../components/SO_PDF_NCE';

function SoPdfGenNCE({ serverAddress, accessToken, userName }) {
	return (
		<div>
			<SOrderNCE serverAddress={serverAddress} accessToken={accessToken} userName={userName} />
		</div>
	);
}

export default SoPdfGenNCE;
