import SettingsGroup from '../SettingsGroup';
import Setting from '../Setting';
import { useAuth } from '../../../context/AuthContext';

const PlatinumCalculatorSettings = () => {
	const { user } = useAuth();

	const rolesPriority = ['admin', 'operations', 'finmanager', 'finance'];
	// .find() linearly indexes [0..], so we can determine order of permissions
	const highestPermissionRole = user?.userRoles?.find(role => rolesPriority.includes(role)) || 'unknown';

	return (
		<div className="space-y-3 w-full">
			{highestPermissionRole === 'finance' || highestPermissionRole === 'finmanager' ? (
				<></>
			) : (
				<>
					<SettingsGroup
						settingsKey="platinumCalculator"
						settingsSubGroup="cost"
						settingsSchema={{ cost: {}, limits: {} }}
						title="Addons Cost"
						subTitle="Set the addons cost that will be used for calculating profitability."
					>
						{({ isEditable, handleInputChange, localSettings, settingsSubGroup }) => (
							<div className="space-y-3">
								<div className="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5 gap-4">
									<Setting
										label="Sev Bc Case"
										name="sevBcCase"
										type="number"
										disabled={!isEditable}
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
									/>
									<Setting
										label="Sev A Hour"
										name="sevAHour"
										type="number"
										disabled={!isEditable}
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
									/>
									<Setting
										label="On-Premise Hour"
										name="onPremiseHour"
										type="number"
										disabled={!isEditable}
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
									/>
									<Setting
										label="Professional Services Hour"
										name="professionalServicesHour"
										type="number"
										disabled={!isEditable}
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
									/>
									<Setting
										label="Consulting Sessions Hour"
										name="consultingSessionsHour"
										type="number"
										disabled={!isEditable}
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
									/>
									<Setting
										label="Microsoft Assessment"
										name="microsoftAssessment"
										type="number"
										disabled={!isEditable}
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
									/>
									<Setting
										label="Dedicated Cloud Engineer Hours"
										name="dedicatedCloudEngineerHours"
										type="number"
										disabled={!isEditable}
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
									/>
									<Setting
										label="Azure Case"
										name="azureCase"
										type="number"
										disabled={!isEditable}
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
									/>
									<Setting
										label="Azure Consulting Sessions Hour"
										name="azureConsultingSessionsHour"
										type="number"
										disabled={!isEditable}
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
									/>
								</div>
							</div>
						)}
					</SettingsGroup>
					<SettingsGroup
						settingsKey="platinumCalculator"
						settingsSubGroup="limits"
						settingsSchema={{ cost: {}, limits: {} }}
						title="Adjustment Limits"
						subTitle="Manage limits for markups and discounts."
					>
						{({ isEditable, handleInputChange, localSettings, settingsSubGroup }) => (
							<div className="space-y-3">
								<div className="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
									<Setting
										label="Max Discount"
										name="maxDiscount"
										type="number"
										disabled={!isEditable}
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
									/>
									<Setting
										label="Max Markup"
										name="maxMarkup"
										type="number"
										disabled={!isEditable}
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
									/>
								</div>
							</div>
						)}
					</SettingsGroup>
					<SettingsGroup
						settingsKey="platinumCalculator"
						settingsSubGroup="profitabilityThresholds"
						settingsSchema={{ lowToMedium: 0, mediumToHigh: 0 }}
						title="Profitability Thresholds"
						subTitle="Set the breakpoints for the profitability thresholds."
					>
						{({ isEditable, handleInputChange, localSettings, settingsSubGroup }) => (
							<div className="space-y-3">
								<div className="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
									<Setting
										label="Low to Medium"
										name="lowToMedium"
										type="number"
										disabled={!isEditable}
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
									/>
									<Setting
										label="Medium to High"
										name="mediumToHigh"
										type="number"
										disabled={!isEditable}
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
									/>
								</div>
							</div>
						)}
					</SettingsGroup>
				</>
			)}
		</div>
	);
};

export default PlatinumCalculatorSettings;
