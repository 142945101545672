import { Search, FilterList } from '@mui/icons-material';
import { useState } from 'react';
import { useDarkMode } from '../../context/DarkModeContext';

function FilterNav({
	isElevatedPermissions,
	salespersons,
	selectedSalesperson,
	selectedMonth,
	searchInput,
	selectedStatus,
	handleSelectedSalesperson,
	handleSelectedMonth,
	handleSearchInput,
	handleSelectedStatus,
}) {
	const { darkMode } = useDarkMode();
	const [isFiltersVisible, setIsFiltersVisible] = useState(false);
	const months = ['All Time', 'This Month', 'Last Month'];
	const statuses = ['All Statuses', 'Draft', 'Pending', 'Signed', 'Approved', 'Rejected'];

	return (
		<div className="flex flex-col w-full">
			<div className="flex items-center gap-4 mb-2">
				<button
					onClick={() => setIsFiltersVisible(!isFiltersVisible)}
					className={`p-2 rounded-lg border ${
						darkMode
							? 'border-slate-600 hover:bg-slate-600 bg-slate-700 text-white'
							: 'border-gray-300 hover:bg-gray-100 bg-white text-gray-700'
					}`}
					title="Toggle Filters"
				>
					<FilterList className={darkMode ? 'text-gray-300' : 'text-gray-500'} />
				</button>
				<div className="flex-1 relative">
					<div
						className={`flex items-center w-full rounded-lg border ${
							darkMode ? 'border-slate-600 bg-slate-700' : 'border-gray-300 bg-white'
						} px-3 py-2`}
					>
						<Search className={darkMode ? 'text-gray-300' : 'text-gray-500'} />
						<input
							className={`ml-2 w-full bg-transparent ${
								darkMode ? 'text-white placeholder-gray-400' : 'text-gray-900 placeholder-gray-500'
							} focus:outline-none`}
							type="text"
							placeholder="Search by customer name..."
							value={searchInput}
							onChange={e => handleSearchInput(e.target.value)}
							maxLength={50}
						/>
					</div>
				</div>
			</div>

			{isFiltersVisible && (
				<div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2">
					{isElevatedPermissions && (
						<select
							className={`block w-full rounded-lg border p-2.5 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 ${
								darkMode
									? 'border-slate-600 bg-slate-700 text-white'
									: 'border-gray-300 bg-white text-gray-900'
							}`}
							onChange={e => handleSelectedSalesperson(e.target.value)}
							value={selectedSalesperson}
						>
							{salespersons?.map((salesperson, index) => (
								<option
									className={darkMode ? 'bg-slate-800' : 'bg-white'}
									key={index}
									value={salesperson.full_name}
								>
									{salesperson.full_name}
								</option>
							))}
						</select>
					)}
					<select
						className={`block w-full rounded-lg border p-2.5 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 ${
							darkMode
								? 'border-slate-600 bg-slate-700 text-white'
								: 'border-gray-300 bg-white text-gray-900'
						}`}
						onChange={e => handleSelectedMonth(e.target.value)}
						value={selectedMonth}
					>
						{months.map((month, index) => (
							<option className={darkMode ? 'bg-slate-800' : 'bg-white'} key={index} value={month}>
								{month}
							</option>
						))}
					</select>
					<select
						className={`block w-full rounded-lg border p-2.5 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 ${
							darkMode
								? 'border-slate-600 bg-slate-700 text-white'
								: 'border-gray-300 bg-white text-gray-900'
						}`}
						onChange={e => handleSelectedStatus(e.target.value)}
						value={selectedStatus}
					>
						{statuses.map((status, index) => (
							<option className={darkMode ? 'bg-slate-800' : 'bg-white'} key={index} value={status}>
								{status}
							</option>
						))}
					</select>
				</div>
			)}
		</div>
	);
}

export default FilterNav;
