/* eslint-disable no-unused-vars */
import React from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import Slider from 'rc-slider';
import { ImDownload } from 'react-icons/im';
import { ImSearch } from 'react-icons/im';
import { ImPlus } from 'react-icons/im';
import { ImFileText } from 'react-icons/im';
import { ImBin } from 'react-icons/im';
import Select from 'react-select';

const ProductView = ({
	rows,
	tttDiscount,
	handleTttDiscountChange,
	currentVendorDiscount,
	handleCurrentVendorDiscountChange,
	currentVendor,
	setCurrentVendor,
	currentVendorOptions,
	currency,
	setCurrency,
	options,
	currencyOptions,
	platinumSupport,
	platSupportOptions,
	handleAddRow,
	onDragEnd,
	handleInputChange,
	handleInputBlur,
	handleEditClick,
	handleDeleteRow,
	handleSaveClick,
	handleSelectChange,
	tempValues,
	currencySymbols,
	totalCurrentVendor,
	totalTtt,
	totalSavings,
	totalAnnualSavings,
	formatMoney,
	handlePreviewPDF,
	handleDownloadPDF,
	setSalesOrderView,
}) => {
	return (
		// FIXME: PRODUCT TABLE - componentize
		<div className="product-table text-black dark:text-white w-full">
			<div className="mb-6">
				<div className="mt-4 flex w-full flex-col space-y-4">
					{/* Control Center div */}
					<div className="flex items-center justify-end space-x-4">
						<div className="rounded-lg">
							<div className="flex items-center space-x-4 bg-gray-200 dark:bg-gray-700 rounded-lg p-2 shadow-md">
								<div className="relative group flex flex-col items-center">
									<button
										onClick={handleAddRow}
										className="control-button text-white transform transition-transform duration-300 ease-in-out"
									>
										<ImPlus className="text-xl rotate-icon text-black dark:text-white" />
									</button>
									<div className="tooltip">Add Row</div>
								</div>
								<div className="relative group flex flex-col items-center">
									<button
										className="control-button text-white transform transition-transform duration-300 ease-in-out"
										onClick={handlePreviewPDF}
									>
										<ImSearch className="text-xl rotate-icon text-black dark:text-white" />
									</button>
									<div className="tooltip">Preview</div>
								</div>
								<div className="relative group flex flex-col items-center">
									<button
										className="control-button text-white transform transition-transform duration-300 ease-in-out"
										onClick={handleDownloadPDF}
									>
										<ImDownload className="text-xl rotate-icon text-black dark:text-white" />
									</button>
									<div className="tooltip">Download</div>
								</div>
							</div>
						</div>
					</div>

					{/* Combined Percentage sliders and Dropdowns container */}
					<div className="rounded-lg overflow-hidden p-4 flex items-center justify-between w-full space-x-4 mx-auto">
						<div className="flex flex-col items-center w-1/5">
							<label className="mb-1 text-black dark:text-white">TTT Discount (%):</label>
							<input
								type="number"
								min={0}
								max={100}
								value={tttDiscount}
								onChange={e => handleTttDiscountChange(parseInt(e.target.value))}
								onKeyDown={e => {
									if (e.key === '-' || e.key === 'e') {
										e.preventDefault();
									}
								}}
                                className="w-3/4 rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
							/>
						</div>
						<div className="flex flex-col items-center w-1/5">
							<label className="mb-1 text-black dark:text-white">Vendor Discount (%):</label>
							<input
								type="number"
								min={0}
								max={100}
								value={currentVendorDiscount}
								onChange={e => handleCurrentVendorDiscountChange(parseInt(e.target.value))}
								onKeyDown={e => {
									if (e.key === '-' || e.key === 'e') {
										e.preventDefault();
									}
								}}
                                className="w-3/4 rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
							/>
						</div>
						<div className="flex flex-col items-center w-1/5">
							<label className="mb-1 text-black dark:text-white">Select Current Vendor:</label>
							<select
								className="w-3/4 rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
								value={currentVendor}
								onChange={e => setCurrentVendor(e.target.value)}
							>
								{currentVendorOptions.map((option, index) => (
									<option key={index} value={option.value}>
										{option.value}
									</option>
								))}
							</select>
						</div>
						<div className="flex flex-col items-center w-1/5">
							<label className="mb-1 text-black dark:text-white">Select Currency:</label>
							<select
								className="w-3/4 rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
								value={currency}
								onChange={e => setCurrency(e.target.value)}
							>
								<optgroup label="US">
									{currencyOptions
										.filter(option => option.value === 'USD' || option.value === 'CAD')
										.map((option, index) => (
											<option key={index} value={option.value}>
												{option.label} {option.value}
											</option>
										))}
								</optgroup>
								<optgroup label="UK">
									{currencyOptions
										.filter(option => option.value !== 'USD' && option.value !== 'CAD')
										.map((option, index) => (
											<option key={index} value={option.value}>
												{option.label} {option.value}
											</option>
										))}
								</optgroup>
							</select>
						</div>
					</div>

					{/* Table and div container */}
					<div className="flex w-full justify-between space-x-2">
						<div className="flex w-full justify-center">
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="droppable-table">
									{provided => (
										<div ref={provided.innerRef} {...provided.droppableProps} className="w-full">
											<table
												id="draggable-table"
												className="w-full table-outline border-collapse text-sm rounded shadow-lg"
											>
												{/* Table Content */}
												<thead className="bg-gray-200 dark:bg-gray-700">
													<tr>
														<th className="header-product p-2 rounded-tl-lg text-black dark:text-white">
															Product
														</th>
														<th className="header-cell p-2 text-black dark:text-white">
															Assigned Seats
														</th>
														<th className="header-cell p-2 text-black dark:text-white">
															Total Seats
														</th>
														<th className="header-cell p-2 text-black dark:text-white">
															MSRP ({currencySymbols[currency]}
															/Seat)
														</th>
														<th className="header-cell p-2 text-black dark:text-white">
															{currentVendor} ({currencySymbols[currency]}
															/Seat)
														</th>
														<th className="header-cell p-2 text-black dark:text-white">
															{currentVendor} (Total)
														</th>
														<th className="header-cell p-2 text-black dark:text-white">
															Trusted Tech Team ({currencySymbols[currency]}
															/Seat)
														</th>
														<th className="header-cell p-2 text-black dark:text-white">
															Trusted Tech Team (Total)
														</th>
														<th className="header-cell p-2 text-black dark:text-white">
															Line Item Savings
														</th>
														<th className="header-cell p-2 rounded-tr-lg text-black dark:text-white"></th>
													</tr>
												</thead>
												<tbody className="bg-white dark:bg-gray-700">
													{rows.map((row, index) => (
														<Draggable key={row.id} draggableId={row.id} index={index}>
															{provided => (
																<tr
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	className="table-row cursor-move hover:bg-gray-100 dark:hover:bg-gray-800 border-b border-gray-300"
																>
																	<td
																		className={`whitespace-normal break-words p-2 text-black dark:text-white ${index === rows.length - 1 ? 'rounded-bl-lg' : ''}`}
																		style={{
																			width: '300px',
																		}}
																	>
																		<div className="flex justify-between">
																			{row.isEditing ? (
																				<>
																					<div className="flex items-center w-full">
																						<span className="mr-2">::</span>
																						<Select
																							value={row.product}
																							onChange={option =>
																								handleSelectChange(
																									option,
																									row.id
																								)
																							}
																							options={
																								row.product &&
																								row.product.label.startsWith(
																									'Platinum Support'
																								)
																									? [
																											{
																												value: 'Platinum Support (Basic)',
																												label: 'Platinum Support (Basic)',
																											},
																											{
																												value: 'Platinum Support (Standard)',
																												label: 'Platinum Support (Standard)',
																											},
																											{
																												value: 'Platinum Support (Premium)',
																												label: 'Platinum Support (Premium)',
																											},
																											{
																												value: 'Platinum Support (Elite)',
																												label: 'Platinum Support (Elite)',
																											},
																										]
																									: options
																							}
																							menuPortalTarget={
																								document.body
																							}
																							styles={{
																								menuPortal: base => ({
																									...base,
																									zIndex: 9999,
																								}),
																								control: base => ({
																									...base,
																									minHeight: 'unset',
																								}),
																							}}
																							className="w-full"
																						/>
																					</div>
																					<button
																						onClick={() =>
																							handleSaveClick(row.id)
																						}
																						className="ml-2 text-blue-500"
																					>
																						Save
																					</button>
																				</>
																			) : (
																				<div className="flex w-full items-center justify-between">
																					<span className="w-full">
																						{row.product
																							? row.product.label
																							: 'Select a product'}
																					</span>
																					<button
																						onClick={() =>
																							handleEditClick(row.id)
																						}
																						className="ml-2 text-blue-500"
																					>
																						Edit
																					</button>
																				</div>
																			)}
																		</div>
																	</td>
																	<td className="p-2">
																		<input
																			type="number"
																			value={
																				tempValues[
																					`${row.id}-assignedSeats`
																				] !== undefined
																					? tempValues[
																							`${row.id}-assignedSeats`
																						]
																					: row.assignedSeats
																			}
																			onChange={e =>
																				handleInputChange(
																					e,
																					row.id,
																					'assignedSeats'
																				)
																			}
																			onKeyDown={e => {
																				if (e.key === '-' || e.key === 'e') {
																					e.preventDefault();
																				}
																			}}
																			min="0"
																			onBlur={() =>
																				handleInputBlur(row.id, 'assignedSeats')
																			}
																			className="input-underline w-full text-black dark:text-white"
																		/>
																	</td>
																	<td className="p-2">
																		<input
																			type="number"
																			value={
																				tempValues[`${row.id}-totalSeats`] !==
																				undefined
																					? tempValues[`${row.id}-totalSeats`]
																					: row.totalSeats
																			}
																			onChange={e =>
																				handleInputChange(
																					e,
																					row.id,
																					'totalSeats'
																				)
																			}
																			onKeyDown={e => {
																				if (e.key === '-' || e.key === 'e') {
																					e.preventDefault();
																				}
																			}}
																			min="0"
																			onBlur={() =>
																				handleInputBlur(row.id, 'totalSeats')
																			}
																			className="input-underline w-full text-black dark:text-white"
																		/>
																	</td>
																	<td className="p-2">
																		{/* edit rights to msrp including plat support*/}
																		<input
																			type="number"
																			value={
																				tempValues[`${row.id}-msrp`] !==
																				undefined
																					? tempValues[`${row.id}-msrp`]
																					: row.msrp
																			}
																			onChange={e =>
																				handleInputChange(e, row.id, 'msrp')
																			}
																			onKeyDown={e => {
																				if (e.key === '-' || e.key === 'e') {
																					e.preventDefault();
																				}
																			}}
																			min="0"
																			onBlur={() =>
																				handleInputBlur(row.id, 'msrp')
																			}
																			className="input-underline w-full text-black dark:text-white"
																		/>
																	</td>
																	<td className="p-2">
																		{currencySymbols[currency]}
																		{(
																			Math.round(
																				(isNaN(row.currentVendorPrice)
																					? 0
																					: row.currentVendorPrice) * 100
																			) / 100
																		).toFixed(2)}
																	</td>
																	<td className="p-2">
																		{currencySymbols[currency]}
																		{(
																			Math.round(
																				(isNaN(row.currentVendorTotal)
																					? 0
																					: row.currentVendorTotal) * 100
																			) / 100
																		).toFixed(2)}
																	</td>
																	<td className="p-2">
																		{currencySymbols[currency]}
																		{(
																			Math.round(
																				(isNaN(row.tttPrice)
																					? 0
																					: row.tttPrice) * 100
																			) / 100
																		).toFixed(2)}
																	</td>
																	<td className="p-2">
																		{currencySymbols[currency]}
																		{(
																			Math.round(
																				(isNaN(row.tttTotal)
																					? 0
																					: row.tttTotal) * 100
																			) / 100
																		).toFixed(2)}
																	</td>
																	<td className="p-2">
																		{currencySymbols[currency]}
																		{(
																			Math.round(
																				(isNaN(row.lineItemSavings)
																					? 0
																					: row.lineItemSavings) * 100
																			) / 100
																		).toFixed(2)}
																	</td>
																	<td className="p-2">
																		<button
																			onClick={() => handleDeleteRow(row.id)}
																			className="text-red-500"
																		>
																			<ImBin className="text-xl" />
																		</button>
																	</td>
																</tr>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</tbody>
											</table>
										</div>
									)}
								</Droppable>
							</DragDropContext>
						</div>
					</div>
					{/* Pricing summary div */}
					<div className="flex items-center justify-center w-full mt-4">
						<div className="text-sm w-full flex justify-between">
							<div>
								<div className="mb-2 text-md font-bold">
									<span className="font-semibold">{currentVendor} / Month: </span>
									<span>
										{currencySymbols[currency]}
										{Math.round(totalCurrentVendor * 100) / 100}
									</span>
								</div>
								<div className="mb-2 text-md font-bold">
									<span className="font-semibold">Trusted Tech Team / Month: </span>
									<span>
										{currencySymbols[currency]}
										{Math.round(totalTtt * 100) / 100}
									</span>
								</div>
							</div>
							<div>
								<div className="mb-2 text-md font-bold">
									<span className="font-semibold">Savings / Month: </span>
									<span>
										{currencySymbols[currency]}
										{formatMoney(totalSavings)}
									</span>
								</div>
								<div className="mb-2 text-md font-bold">
									<span className="font-semibold">Savings / Year: </span>
									<span>
										{currencySymbols[currency]}
										{formatMoney(totalAnnualSavings)}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductView;
