import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Commissions from './pages/Commissions';
import MyDocuments from './pages/myDocuments';
import MyDealDesk from './pages/dealdesk';
// import ConsumptionReport from "./pages/ConsumptionReport";
import CRPDFGEN from './pages/CR_PDF_GEN';
import SOPDFGEN from './pages/SO_PDF_GEN';
import SOPDFGENNCE from './pages/SO_PDF_GEN_NCE';
// import ConsumptionReportGen from "./pages/ConsumptionReportGen";
import { useIsAuthenticated } from '@azure/msal-react';
import PlatinumSupportCalculator from './pages/PlatinumSupportCalculator';
import Settings from './pages/Settings';
import LeftSidebar from './components/sidebar/LeftSidebar';
import MainHeader from './components/mainHeader/MainHeader';
import { useDarkMode } from './context/DarkModeContext';
import logoDark from './assets/ttt-logo.png';
import logoLight from './assets/ttt-logo_light.png';
// import SalesOrder from "./pages/SalesOrder";
import { Authorize, operationsDynamic, useAuth } from './context/AuthContext';
import { CustomNavigationClient } from './NavigationClient';
import Analytics from './pages/Analytics';
import Forbidden from './pages/Forbidden';
import NotFound from './pages/NotFound';
import { useSettings } from './context/SettingsContext';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

export default function App({ pca }) {
	const { darkMode } = useDarkMode();
	const { initSettings } = useSettings();
	const isAuthenticated = useIsAuthenticated();
	const { accessToken, user, handleLogin } = useAuth();

	useEffect(() => {
		if (user) {
			initSettings();
		}
	}, [accessToken, user]);

	const navigate = useNavigate();
	const location = useLocation();
	const navigationClient = new CustomNavigationClient(navigate);
	pca.setNavigationClient(navigationClient);

	const getActivePageName = path => {
		const pageMap = {
			'/home': 'Home',
			'/commissions': 'Commissions',
			'/documents': 'My Documents',
			'/consumption-reports': 'Consumption Report',
			'/sales-orders': 'Sales Order Renewal',
			'/sales-orders-nce': 'Sales Order Acquisition',
			'/platinum-calculator': 'Platinum Calculator',
			'/analytics': 'Analytics',
			'/dealdesk': 'Deal Desk',
			'/settings': 'Settings',
		};

		if (path.startsWith('/settings')) {
			return 'Settings';
		}

		return pageMap[path] || 'Home'; // Default to Home if path not found
	};

	const activePage = getActivePageName(location.pathname);

	return (
		<>
			<div className="min-h-screen text-white bg-gray-100 dark:bg-slate-800">
				{isAuthenticated ? (
					<main className="flex bg-gray-300 dark:bg-slate-900">
						{user?.entraGroup === 'default' ? (
							<div>Unrecognized user. Please contact Operations team to ask for access</div>
						) : (
							<div className="flex w-full h-screen bg-custom-green-gradient">
								<div className="flex-shrink-0">
									<LeftSidebar />
								</div>
								<div className="flex flex-col flex-grow my-3 mr-3 overflow-hidden bg-gray-100 rounded-lg shadow-lg dark:bg-gray-800">
									<MainHeader userName={user?.userName} activePage={activePage} />
									<div className="flex-grow h-0 p-6 pt-0 overflow-x-hidden overflow-y-auto scrollbar-hide">
										<Pages />
									</div>
								</div>
								<ToastContainer autoClose={false} />
							</div>
						)}
					</main>
				) : (
					<div className="flex w-screen min-h-screen">
						<div className="w-full flex flex-col items-center justify-center gap-5 shadow-lg bg-slate-200 dark:bg-slate-800 bg-custom-green-gradient">
							<div className="max-h-[22rem] rounded-2xl pb-2 flex flex-col items-center gap-12">
								<div className="flex flex-col items-center gap-5 pb-0">
									<img className="w-[5rem]" src={darkMode ? logoDark : logoLight} alt="TTT Logo" />
									<h1 className="text-6xl font-bold text-zinc-800 dark:text-slate-300">Sales Hub</h1>
									<p className="text-xl font-bold rounded-md bg-gray-300 px-2 py-1 text-white bg-gradient-to-r from-blue-400 via-green-400 to-blue-400 bg-[length:200%_200%] animate-moveGradient">
										Beta
									</p>
								</div>
								<button onClick={handleLogin} className="btn-primary w-max">
									Sign in
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

function Pages() {
	const { darkMode } = useDarkMode();

	return (
		<Routes>
			<Route path="/">
				<Route index element={<Navigate to="/home" />} />
				<Route path="home" element={<Home />} />
				<Route path="unauthorized" element={<Forbidden />} />
				<Route path="not-found" element={<NotFound />} />
				<Route
					element={
						<Authorize
							allowedRoles={[
								'operations',
								'ammanager',
								'aemanager',
								'manager',
								'finance',
								'finmanager',
								'leadership',
								'am',
								'ae',
							]}
						/>
					}
				>
					<Route path="documents" element={<MyDocuments />} />
				</Route>
				<Route
					element={
						// TODO: Update permissions once Deal Desk is complete
						<Authorize allowedRoles={['operations', 'admin']} />
					}
				>
					<Route path="dealdesk" element={<MyDealDesk />} />
				</Route>
				<Route
					element={
						<Authorize
							allowedRoles={[
								'operations',
								'am',
								'manager',
								'ammanager',
								'finance',
								'finmanager',
								'leadership',
							]}
						/>
					}
				>
					<Route path="commissions" element={<Commissions />} />
					<Route path="sales-orders" element={<SOPDFGEN />} />
					<Route path="sales-orders-nce" element={<SOPDFGENNCE />} />
				</Route>
				<Route
					element={
						<Authorize
							allowedRoles={[
								'operations',
								'admin',
								'ae',
								'aemanager',
								'finance',
								'finmanager',
								'leadership',
							]}
						/>
					}
				>
					<Route path="consumption-reports" element={<CRPDFGEN />} />
				</Route>
				<Route
					element={
						<Authorize
							allowedRoles={[
								'operations',
								'platcalc',
								'am',
								'ae',
								'admin',
								'manager',
								'ammanager',
								'aemanager',
								'finance',
								'finmanager',
								'leadership',
							]}
						/>
					}
				>
					<Route path="platinum-calculator" element={<PlatinumSupportCalculator darkMode={darkMode} />} />
				</Route>
				<Route element={<Authorize allowedRoles={['admin', 'operations', 'finance', 'finmanager']} />}>
					<Route path="analytics" element={<Analytics />} />
					<Route path="settings/:menu" element={<Settings />} />
					<Route path="settings" element={<Navigate to="/settings/general" />} />
				</Route>
			</Route>
		</Routes>
	);
}
