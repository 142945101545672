import { useDarkMode } from '../../context/DarkModeContext';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { AccountCircle } from '@mui/icons-material';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useAuth } from '../../context/AuthContext';

function MainHeader({ userName, activePage }) {
	const { darkMode, setDarkMode } = useDarkMode();
	const { handleLogout, user } = useAuth();

	return (
		<div className="sticky top-0 z-10 flex items-center px-6 py-3">
			<div className="flex items-center">
				<h1 className="mr-2 w-max text-2xl font-semibold text-slate-800 dark:text-white">{activePage}</h1>
			</div>
			<div className="w-full"></div>
			<div className="flex items-center justify-end sm:space-x-2">
				<div className="relative inline-block text-left">
					<div className="flex items-center">
						<div
							onClick={() => setDarkMode(!darkMode)}
							className={`${darkMode ? 'text-[#f2c75a]' : 'text-[#6d5dd6]'} hover:cursor-pointer`}
						>
							<Tippy
								content="Theme toggle"
								placement="left"
								animation="fade"
								theme={darkMode ? 'dark' : 'light'}
								delay={500}
							>
								{darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
							</Tippy>
						</div>
						{/* <button> */}
						<div
							className="flex w-max items-center space-x-2 rounded-xl ms-2 hover:cursor-pointer"
							onClick={() => handleLogout()}
						>
							<div className="flex rounded-full bg-gray-500/15 p-2">
								<p className="flex whitespace-nowrap pr-2 text-xs font-semibold text-slate-500">
									<span className="hidden items-center gap-1 text-zinc-800 sm:flex dark:text-neutral-100">
										{user?.profilePhoto ? (
											<img
												src={user?.profilePhoto}
												alt="Profile Picture"
												width={24}
												height={24}
												className="rounded-full"
											/>
										) : (
											<AccountCircle />
										)}
										{userName}
									</span>
									{/* <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                            className="ml-2 hidden w-4 sm:inline-flex"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg> */}
								</p>
							</div>
						</div>
						{/* </button> */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default MainHeader;
