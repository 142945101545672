import { useState, useEffect, useCallback } from 'react';
import LogoLoading from '../common/LogoLoading';
import { getRedisKeyValue } from '../../utils';
import { useSettings } from '../../context/SettingsContext';
import { useAuth } from '../../context/AuthContext';
import { settingsPermissionsMatrix } from '../../pages/Settings';
import { serverAddress } from '../../authConfig';

// Generic Settings Group Component
const SettingsGroup = ({
	settingsKey,
	settingsSubGroup,
	settingsSchema,
	title,
	subTitle,
	alwaysEditable = false,
	children, // Accepts child elements (static input fields)
}) => {
	const { settings, updateSetting, initSettings } = useSettings();
	const { user } = useAuth();
	const [localSettings, setLocalSettings] = useState({});
	const [initialSettings, setInitialSettings] = useState({});
	const [isEditable, setIsEditable] = useState(alwaysEditable);
	const [hasChanges, setHasChanges] = useState(false);

	useEffect(() => {
		if (settings?.[settingsKey]) {
			const updatedSettings = {
				...settingsSchema,
				...(settings?.[settingsKey] || {}),
			};
			setLocalSettings(updatedSettings);
			setInitialSettings(updatedSettings);
		}
	}, [settings, settingsKey, settingsSchema]);

	// Detect changes in the local settings
	useEffect(() => {
		if (localSettings) {
			const isChanged = JSON.stringify(localSettings) !== JSON.stringify(initialSettings);
			setHasChanges(isChanged);
		}

		return () => {
			setHasChanges(false); // Cleanup on component unmount
		};
	}, [localSettings, initialSettings]);

	const permissions = settingsPermissionsMatrix[settingsKey]?.[settingsSubGroup];

	const rolesPriority = ['admin', 'operations', 'manager', 'am', 'ae', 'platcalc', 'bdr'];
	// .find() linearly indexes [0..], so we can determine order of permissions
	const highestPermissionRole = user?.userRoles?.find(role => rolesPriority.includes(role)) || 'unknown';

	if (!permissions || !permissions.includes(highestPermissionRole)) {
		return null;
	}

	const handleInputChange = (e, subCategory) => {
		const { name, type, checked, value } = e.target;

		// Determine the input value based on type
		let inputValue;
		if (type === 'checkbox') {
			inputValue = checked;
		} else if (type === 'number') {
			inputValue = parseFloat(value) || 0; // Default to 0 if value is empty
		} else {
			inputValue = value; // Handle text inputs like textareas
		}

		// Update the local settings state
		setLocalSettings(prevSettings => ({
			...prevSettings,
			[subCategory]: {
				...prevSettings[subCategory],
				[name]: inputValue,
			},
		}));
	};

	// Toggle the editable state
	const handleEditToggle = () => {
		setIsEditable(prevEditable => {
			if (prevEditable) {
				initSettings(); // Fetch fresh data if editing is canceled
			}
			return !prevEditable;
		});
		setHasChanges(false);
	};

	// Save updated settings
	const handleSave = async () => {
		const userConfirmed = window.confirm(`Are you sure you want to update the ${title} settings?`);
		if (!userConfirmed) return;

		const currentDateTime = new Date().toISOString().slice(0, 16);

		const updatedSettings = {
			...localSettings,
			[settingsSubGroup]: {
				...localSettings[settingsSubGroup],
				lastUpdated: currentDateTime,
			},
		};

		try {
			console.log(`Saving updated ${settingsKey} settings:`, updatedSettings);

			// Update the settings context
			const response = await updateSetting(settingsKey, updatedSettings);
			if (response.status === 200) {
				setLocalSettings(updatedSettings);
				setInitialSettings(updatedSettings);
				setHasChanges(false);
				setIsEditable(alwaysEditable);
				alert(`${title} updated successfully!`);
			}
		} catch (error) {
			console.error(`Error saving ${settingsKey} settings:`, error);
		}
	};

	return (
		<div className="p-6 bg-white dark:bg-slate-700 rounded-lg shadow-md flex justify-center items-center">
			<div className="space-y-4 w-full">
				<div className="flex justify-between">
					<div>
						<h3 className="flex items-center gap-1 text-md font-medium text-black dark:text-white">
							{title}
						</h3>
						<p className="mb-3 text-sm text-black/50 dark:text-white/50">{subTitle}</p>
					</div>
					{/* Save for later - display locked/unlocked */}
					{/*<div className="flex flex-col items-end gap-1 text-sm text-gray-500 dark:text-gray-400">
                             {isEditable ? (
                                <>
                                    <LockOpen style={{ fontSize: '14px' }} /> <span className="ml-1 text-xs">Unlocked</span>
                                </>
                            ) : (
                                <>
                                    <Lock style={{ fontSize: '14px' }} /> <span className="ml-1 text-xs">Locked</span>
                                </>
                            )} 
                        </div>
                    </div>*/}
				</div>
				{/* Render Static Child Inputs Passed to SettingsGroup */}
				{children({
					isEditable,
					handleInputChange,
					localSettings,
					settingsSubGroup,
				})}
				<div className="flex justify-between">
					<div className="space-x-3">
						{/* Conditionally render Edit Button based on alwaysEditable */}
						{!alwaysEditable && (
							<button
								onClick={handleEditToggle}
								className={`text-white px-3 py-2 rounded ${isEditable ? 'bg-red-500' : 'bg-blue-500'}`}
							>
								{isEditable ? 'Cancel' : 'Edit'}
							</button>
						)}
						{/* Show Save Button if there are changes */}
						{hasChanges && (
							<button onClick={handleSave} className="px-3 py-2 rounded bg-blue-500 text-white">
								Save {title}
							</button>
						)}
					</div>
					<div className="flex items-end text-xs text-gray-600 dark:text-gray-300">
						Last Updated:{' '}
						{localSettings[settingsSubGroup]?.lastUpdated
							? new Date(`${localSettings[settingsSubGroup]?.lastUpdated}Z`).toLocaleString('en-US', {
									timeZone: 'America/Los_Angeles',
									year: 'numeric',
									month: 'numeric',
									day: 'numeric',
									hour: 'numeric',
									minute: '2-digit',
									hour12: true,
									timeZoneName: 'short',
								})
							: 'Not yet updated'}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SettingsGroup;
