import Setting from '../Setting';
import SettingsGroup from '../SettingsGroup';

const ConsumptionReportSettings = () => (
	<div className="space-y-3 w-full">
		<SettingsGroup
			settingsKey="consumptionReport"
			settingsSubGroup="subGroupName"
			settingsSchema={{ rates: {} }}
			title="Title"
			subTitle="Description."
		>
			{({ isEditable, handleInputChange, localSettings, settingsSubGroup }) => (
				<div className="space-y-3">
					<div className="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
						{/* Add settings here using Setting component */}
						<Setting
							label="Label"
							name="name"
							type="text"
							localSettings={localSettings}
							settingsSubGroup={settingsSubGroup}
							handleInputChange={handleInputChange}
							disabled={!isEditable}
						/>
					</div>
				</div>
			)}
		</SettingsGroup>
	</div>
);

export default ConsumptionReportSettings;
