/* eslint-disable no-undef */
import basicIcon from './assets/basic-icon.png';
import standardIcon from './assets/standard-icon.png';
import premiumIcon from './assets/premium-icon.png';
import eliteIcon from './assets/elite-icon.png';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { serverAddress } from './authConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const levenshteinDistance = (s, t) => {
	// This function calculates the least amount of changes needed to transform string s into string t
	// Useful for fuzzy string matching inputs from left to right, spell checking, etc.
	if (!s.length) return t.length;
	if (!t.length) return s.length;
	const arr = [];
	for (let i = 0; i <= t.length; i++) {
		arr[i] = [i];
		for (let j = 1; j <= s.length; j++) {
			arr[i][j] =
				i === 0
					? j
					: Math.min(
							arr[i - 1][j] + 1,
							arr[i][j - 1] + 1,
							arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1)
						);
		}
	}
	// console.info(`${s} -> ${t} = ${arr[t.length][s.length]}`);
	return arr[t.length][s.length];
};

export const formatMoney = amount => {
	return new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(amount);
};

export const getPlatinumSupportDetails = tier => {
	switch (tier) {
		case 'Basic':
			return {
				icon: basicIcon,
				benefits: ['Foundational Account Support'],
			};
		case 'Standard':
			return {
				icon: standardIcon,
				benefits: [
					'Foundational Account Support',
					'Access to US-Based Support Engineers',
					'24/7/365 Global Support',
					'Preferred Pricing on Professional Services',
					'Priority Ticket Routing',
					'Severity B & C (Escalation to Microsoft Premier)',
					'Consulting Sessions: 12 hours (1 hour/month)',
					'Severity B & C Cases: 15 cases',
				],
			};
		case 'Premium':
			return {
				icon: premiumIcon,
				benefits: [
					'Foundational Account Support',
					'Access to US-Based Support Engineers',
					'24/7/365 Global Support',
					'Preferred Pricing on Professional Services',
					'Priority Ticket Routing',
					'Severity B & C (Escalation to Microsoft Premier)',
					'Consulting Sessions: 24 hours (2 hours/month)',
					'Severity B & C Cases: 50 cases',
					'Severity A (Escalation to Microsoft Premier): 2 hours',
					'On Premise / Hybrid Support (Escalation to Microsoft Premier): 2 hours',
					'Microsoft / Azure Assessment: 20 hours',
					'Dedicated Cloud Support Engineer: 1',
				],
			};
		case 'Elite':
			return {
				icon: eliteIcon,
				benefits: [
					'Foundational Account Support',
					'Access to US-Based Support Engineers',
					'24/7/365 Global Support',
					'Preferred Pricing on Professional Services',
					'Priority Ticket Routing',
					'Severity B & C (Escalation to Microsoft Premier)',
					'Consulting Sessions: 48 hours (2 hours/month)',
					'Severity B & C Cases: 100 cases',
					'Severity A (Escalation to Microsoft Premier): 5 hours',
					'On Premise / Hybrid Support (Escalation to Microsoft Premier): 5 hours',
					'Microsoft / Azure Assessment: 100 hours',
					'Dedicated Cloud Support Engineer: 1',
					'Professional Services: 48 hours',
				],
			};
		default:
			return null;
	}
};

export const getCommissionForItem = (item, nceCosts, desiredFields) => {
	const itemPrice = parseFloat(item.price);
	const lowerBound = itemPrice * 0.6;
	const upperBound = itemPrice * 1.4;
	const nceItem = nceCosts.find(nceItem => {
		return (
			nceItem.unit_price !== 0 && // Disregard 0 acquired cost because we won't pay out commissions on free/trial licenses
			item.name.toLowerCase().trim().includes(nceItem.product_title.trim().toLowerCase())
		);
	});

	let nceCost =
		nceItem?.unit_price >= lowerBound && nceItem?.unit_price <= upperBound
			? (nceItem?.unit_price ?? 0)
			: (nceItem?.unit_price ?? 0) / 12;

	// List of product overwrites / exceptions
	if (nceItem?.product_title.includes('Microsoft 365 E3')) {
		nceCost = 28.8;
	}

	const profit = (itemPrice - nceCost) * item.qty;
	const months = 12; // Assuming 12 months for now
	const annualProfit = profit * months;
	let commission = item.name.toLowerCase().includes('platinum') ? annualProfit * 0.0 : annualProfit * 0.04;

	if (commission < 0) {
		commission = 0;
	}

	const billingPlan = nceItem?.billing_plan ?? 'none';

	if (desiredFields === 'ALL') {
		return [itemPrice, nceCost, profit, annualProfit, commission, billingPlan];
	}

	return commission;
};

export const generateSHA256 = async message => {
	const msgBuffer = new TextEncoder().encode(message);
	const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
	return hashHex;
};

//OG
export const uploadSalesOrderToCosmos = async (
	addons,
	formData,
	userName,
	serverAddress,
	accessToken,
	salesOrderType,
	totalContractValue,
	id = null
) => {
	const pricingSummary = addons.map(addon => {
		const showBillingAsMonthly = [
			'CFQ7TTC0MM8R|0002|P1Y|annual|US|USD', // Copilot for M365
		];

		const variants = addon.pkMsrpVariants?.[addon?.matchListName];
		const matchedVariant = variants?.find(variant => variant.pk_msrp === addon?.matchListLabel);

		const matchedUnitPrice = matchedVariant?.unit_price
			? showBillingAsMonthly.includes(matchedVariant.pk_msrp)
				? matchedVariant?.unit_price / 12
				: matchedVariant?.unit_price
			: 0;

		const matchedUnitPriceInUsd = matchedVariant?.unit_price_in_usd
			? showBillingAsMonthly.includes(matchedVariant.pk_msrp)
				? matchedVariant?.unit_price_in_usd / 12
				: matchedVariant?.unit_price_in_usd
			: 0;

		console.info(addon);
		const effectiveStartDate = new Date(addon.effectiveStartDate);
		const effectiveEndDate = new Date(addon.effectiveEndDate);

		return {
			addonID: addon.id,
			productName: addon.name,
			description: addon?.description,
			unitPrice: addon.unit_price / 100,
			originalPrice: addon.originalPrice / 100,
			quantity: addon.quantity,
			matchedSkuTitle: addon.matchListName || 'N/A',
			matchedPkID: addon.matchListLabel || 'N/A',
			matchedUnitPrice: matchedUnitPrice,
			matchedUnitPriceInUsd: matchedUnitPriceInUsd,
			effectiveStartDate: effectiveStartDate.toISOString(),
			effectiveEndDate: effectiveEndDate.toISOString(),
		};
	});

	const totalAmount = addons.reduce((total, addon) => total + addon.amount, 0);
	// const totalContractValue = totalAmount * (formData.termMonths || 1);
	let salesPersonName = formData.salesPersonName || userName;

	// Separate the addonID because its a GUID generated on each render
	// This allows accurate hashing
	// We then revert to using updatedRows after hash creation
	const hashablePrices = pricingSummary.map(lineItem => {
		const hashablePrice = { ...lineItem, addonID: undefined };
		return hashablePrice;
	});

	const jsonSummary = {
		id: id || uuidv4(),
		salesOrderId: formData.salesOrderId || uuidv4(),
		chargebeeSubscriptionId: formData.chargebeeSubscriptionId,
		validThrough: formData.validThrough.toISOString(),
		renewalDate: formData.renewalDate.toISOString(),
		termMonths: formData.termMonths,
		netTerms: formData.netTerms,
		billingFrequency: formData.billingFrequency,
		companyName: formData.companyName,
		salesPersonName: salesPersonName,
		currency: formData.currency,
		status: formData.status,
		platinumSupport: formData.platinumSupport,
		salesForceAccountId: formData.salesForceAccountId,
		salesForceOpportunityId: formData.salesForceOpportunityId,
		pricingSummary: hashablePrices,
		totalAmount: (totalAmount / 100).toFixed(2),
		totalContractValue: totalContractValue?.toFixed(2), // Undefined with CR -> NCE Sales Order
	};

	let createdDate = new Date();
	const jsonHashableString = JSON.stringify({
		...jsonSummary,
		// Exclude following items when generating hash
		id: undefined,
		salesOrderId: undefined,
	});
	const jsonHash = await generateSHA256(jsonHashableString);
	const hashSubstring = jsonHash.substring(0, 32);

	jsonSummary['createdDate'] = createdDate.toISOString();
	jsonSummary['createdBy'] = userName;
	jsonSummary['hash'] = hashSubstring;
	jsonSummary['salesOrderType'] = salesOrderType;
	jsonSummary['pricingSummary'] = pricingSummary;

	try {
		let response;

		if (id && salesOrderType === 'RENEWAL') {
			//update sales order
			response = await axios.put(`${serverAddress}/sales-orders/renewal`, jsonSummary, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': userName,
				},
			});
			if (response.status === 200) {
				toast.success('Updated!', {
					position: 'top-right',
					autoClose: 5000,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
				console.log('Updated existing sales order:', response);
				return true;
			}
		} else if (salesOrderType === 'RENEWAL') {
			console.info('CREATING NEW SALES ORDER');
			//create sales order
			response = await axios.post(`${serverAddress}/sales-orders/renewal`, jsonSummary, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': userName,
				},
			});
			if (response.status === 201) {
				toast.success('Created!', {
					position: 'top-right',
					autoClose: 5000,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
			}
			console.log('Created new sales order:', response);
			return true;
		} else if (salesOrderType === 'NCE') {
			//create sales order
			response = await axios.post(`${serverAddress}/sales-orders/nce`, jsonSummary, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': userName,
				},
			});
			if (response.status === 201) {
				toast.success('Created!', {
					position: 'top-right',
					autoClose: 5000,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
			}
			console.log('Created new sales order:', response);
			return true;
		}
	} catch (error) {
		if (error.response && error.response.status === 422) {
			error.response.data.detail.forEach(err => {
				const errorField = err.loc[err.loc.length - 1];
				toast.error(`${errorField}: ${err.msg}`, {
					autoClose: 5000,
					position: 'top-right',
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
			});
		} else {
			console.error('An error occurred:', error);
		}
		return false;
	}
};

export const uploadConsumptionReportToCosmos = async (
	updatedRows,
	clientCompany,
	clientEmail,
	clientName,
	clientPhone,
	tttDiscount,
	vendorDiscount,
	selectedSender,
	referenceCode,
	currency,
	userName,
	serverAddress,
	accessToken,
	id = null
) => {
	const sender = {
		...selectedSender,
		value: selectedSender?.value || selectedSender?.full_name,
		label: selectedSender?.label || selectedSender?.full_name,
	};

	const jsonSummary = {
		id: id || uuidv4(),
		consumptionReportID: id || uuidv4(),
		clientCompany: clientCompany,
		clientEmail: clientEmail,
		clientName: clientName,
		clientPhone: clientPhone,
		tttDiscount: tttDiscount,
		vendorDiscount: vendorDiscount,
		selectedSender: sender,
		referenceCode: referenceCode,
		currency: currency,
		createdBy: userName,
		status: 'Draft',
		rows: updatedRows,
	};

	let createdDate = new Date();
	const jsonHashableString = JSON.stringify({
		...jsonSummary,
		id: undefined,
		consumptionReportID: undefined,
	});

	const jsonHash = await generateSHA256(jsonHashableString);
	const hashSubstring = jsonHash.substring(0, 32);

	// jsonSummary["rows"] = updatedRows; // use updatedRows after hash creation
	jsonSummary['createdDate'] = createdDate.toISOString();
	jsonSummary['createdBy'] = userName;
	jsonSummary['hash'] = hashSubstring;

	try {
		let response;

		if (id) {
			response = await axios.put(`${serverAddress}/consumption-reports`, jsonSummary, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': userName,
				},
			});
			if (response.status === 200) {
				toast.success('Updated!', {
					position: 'top-right',
					autoClose: 5000,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
				console.log('Updated existing consumption report:', response);
				return true;
			}
		} else {
			response = await axios.post(`${serverAddress}/consumption-reports`, jsonSummary, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': userName,
				},
			});
			if (response.status === 201) {
				toast.success('Created!', {
					position: 'top-right',
					autoClose: 5000,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
			}
			console.log('Created new consumption report:', response);
			return true;
		}
	} catch (error) {
		if (error.response && error.response.status === 422) {
			error.response.data.detail.forEach(err => {
				const errorField = err.loc[err.loc.length - 1];
				toast.error(`${errorField}: ${err.msg}`, {
					autoClose: 5000,
					position: 'top-right',
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
			});
		} else {
			console.error('An error occurred:', error);
		}
		return false;
	}
};

export const uploadSalesOrderNCEtoCosmos = async (
	pricingSummary,
	formData,
	userName,
	serverAddress,
	accessToken,
	id = null
) => {
	const jsonSummary = {
		salesOrderId: formData.salesOrderId || uuidv4(),
		termMonths: formData.termMonths || '',
		validThrough: formData.validThrough ? formData.validThrough.toISOString() : '',
		servicesStartDate: formData.servicesStartDate ? formData.servicesStartDate.toISOString() : '',
		servicesEndDate: formData.servicesEndDate ? formData.servicesEndDate.toISOString() : '',
		netTerms: formData.netTerms || '',
		billingFrequency: formData.billingFrequency || '',
		salesPersonName: formData.salesPersonName || userName,
		companyName: formData.companyName || '',
		companyAddress: formData.companyAddress || '',
		companyCity: formData.companyCity || '',
		companyState: formData.companyState || '',
		companyZIP: formData.companyZIP || '',
		companyCountry: formData.companyCountry || '',
		currency: formData.currency || '',
		platinumSupport: formData.platinumSupport || '',
		salesForceAccountId: 'UNKNOWN',
		salesForceOpportunityId: 'UNKNOWN',
		pricingSummary: pricingSummary.map(addon => ({
			addonID: addon.id || uuidv4(),
			productName: addon.name || 'Unknown Product',
			unitPrice: addon.tttPrice || 0,
			originalPrice: addon.originalPrice || 0,
			quantity: addon.assignedSeats || 0,
			matchedSkuTitle: addon.matchedSkuTitle || 'N/A',
			matchedPkID: addon.matchedPkID || 'N/A',
			matchedUnitPrice: addon.matchedUnitPrice || 0,
			matchedUnitPriceInUsd: addon.matchedUnitPriceInUsd || 0,
			description: addon.description || '',
			effectiveStartDate: addon.effectiveStartDate,
		})),
		totalAmount: pricingSummary.reduce(
			(total, addon) => total + (addon.tttPrice || 0) * (addon.assignedSeats || 0),
			0
		),
		totalContractValue: pricingSummary.reduce(
			(total, addon) => total + (addon.tttPrice || 0) * (addon.assignedSeats || 0) * 12,
			0
		),
		status: formData.status || 'Draft',
		salesOrderType: 'NCE',
		id: id || uuidv4(),
	};

	console.log('Uploading to Cosmos: ', jsonSummary);
	jsonSummary['total'] = pricingSummary.reduce(
		(total, addon) => total + (addon.tttPrice || 0) * (addon.assignedSeats || 0),
		0
	);

	let createdDate = new Date();
	const jsonHashableString = JSON.stringify({
		...jsonSummary,
		id: undefined,
	});
	const jsonHash = await generateSHA256(jsonHashableString);
	const hashSubstring = jsonHash.substring(0, 32);
	jsonSummary['createdDate'] = createdDate.toISOString();
	jsonSummary['createdBy'] = userName;
	jsonSummary['hash'] = hashSubstring;

	try {
		let response;

		if (id) {
			//update existing sales order
			response = await axios.put(`${serverAddress}/sales-orders/nce`, jsonSummary, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': userName,
				},
			});
			if (response.status === 201) {
				toast.success('Updated!', {
					position: 'top-right',
					autoClose: 5000,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
				console.log('Updated existing sales order NCE:', response);
				return true;
			}
		} else {
			//create new sales order
			response = await axios.post(`${serverAddress}/sales-orders/nce`, jsonSummary, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': userName,
				},
			});
			if (response.status === 201) {
				toast.success('Created!', {
					position: 'top-right',
					autoClose: 5000,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
				console.log('Created new sales order NCE:', response);
				console.log('Uploading Sales Order NCE to Cosmos with payload:', jsonSummary);
				return true;
			}
		}
	} catch (error) {
		if (error.response && error.response.status === 422) {
			error.response.data.detail.forEach(err => {
				const errorField = err.loc[err.loc.length - 1];
				toast.error(`${errorField}: ${err.msg}`, {
					autoClose: 5000,
					position: 'top-right',
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
			});
		} else {
			console.error('An error occurred:', error);
		}
		return false;
	}
};

export const getRedisKeyValue = async (key, accessToken, userName) => {
	try {
		const response = await axios.get(`${serverAddress}/admin?key=${key}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
				'X-User-Name': `${userName}`,
			},
		});
		return response;
	} catch (error) {
		console.error('Error fetching key from Redis:', error);
	}
};

export const setRedisKeyValue = async (key, value, accessToken, userName) => {
	try {
		const body = {
			key: key,
			val: value,
		};
		console.log('Setting key in Redis:', body);
		const response = await axios.post(`${serverAddress}/admin`, body, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
				'X-User-Name': `${userName}`,
			},
		});
		return response;
	} catch (error) {
		console.error('Error setting key in Redis:', error);
	}
};

//date filter
const dateFilter = document => {
	const date = pstDate(document?._ts);
	const month = date.split('/')[0];
	if (month === selectedMonth) {
		return document;
	} else if (selectedMonth === 'All Time' || selectedMonth === '') {
		return document;
	}
};

//status filter (for sales orders only)
const statusFilter = (document, isSalesOrder) => {
	if (!isSalesOrder) return document; //no status filter for consumption reports
	if (selectedStatus === 'All Statuses') {
		return document;
	} else if (document?.status) {
		return document.status === selectedStatus;
	}
};

//salesperson filter
const salespersonFilter = (document, isSalesOrder) => {
	if (selectedSalesperson === 'All Salespersons') {
		return document;
	} else if (isSalesOrder && document?.salesPersonName) {
		return document.salesPersonName === selectedSalesperson;
	} else if (!isSalesOrder && document?.selectedSender?.full_name) {
		return document.selectedSender.full_name === selectedSalesperson;
	}
};

export const getMarkersForMonth = (targetMonth, salesOrders) => {
	return salesOrders?.reduce(
		(acc, current) => {
			let orderMonth = 0; // No 0th month, so this will prevent false matches
			if (current?.signedStatus) {
				const signedDate = new Date(current.signedStatus.updated);
				const signedMonth = signedDate.getMonth() + 1; // getMonth() returns 0-based month, so add 1
				if (signedMonth !== NaN) {
					orderMonth = signedMonth;
				}
			}
			if (orderMonth === targetMonth && (current.status === 'Signed' || current.status === 'Approved')) {
				return {
					totalContractValues: acc.totalContractValues + Number(current.totalContractValue),
					commission: acc.commission + current.commission,
					monthlyProfit: acc.monthlyProfit + current.monthlyProfit,
					profitTcvs: acc.profitTcvs + current.monthlyProfit * Number(current.termMonths),
					cogs: acc.cogs + current.monthlyCostOfGoods,
				};
			}
			return acc;
		},
		{
			totalContractValues: 0,
			commission: 0,
			monthlyProfit: 0,
			profitTcvs: 0,
			cogs: 0,
		}
	);
};
