import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import useDebounce from '../hooks/useDebounce';
import { useQuery } from 'react-query';
import { formatMoney, getMarkersForMonth } from '../utils';
import axios from 'axios';
import { serverAddress } from '../authConfig';
import BarChartStackBySign from '../components/analytics/BarChartStackBySign';

export default function Analytics() {
	const { user, accessToken } = useAuth();

	const [selectedSalesperson, setSelectedSalesperson] = useState(user?.userName);

	const fetchSalespersons = async () => {
		// AMs should only be able to see their own sales orders
		if (!user?.isElevatedPermissions) return [{ full_name: user?.userName }];

		try {
			const response = await axios.get(`${serverAddress}/salespersons/account-managers`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': `${user?.userName}`,
				},
			});
			if (response.status === 200) {
				return response.data.salespersons;
			}
		} catch (error) {
			console.error(error);
		}
	};

	const {
		data: salespersons,
		error: salespersonsError,
		isLoading: salespersonsIsLoading,
	} = useQuery({
		queryKey: ['salespersons', user?.userName],
		queryFn: () => fetchSalespersons(),
		staleTime: 30 * 60 * 1000, // Cache data for 30 mins. Number of AMs doesn't change often
	});

	const fetchUsersSalesOrders = async salesperson => {
		if (salesperson === undefined) return;

		setSelectedSalesperson(salesperson);
		try {
			const response = await axios.get(`${serverAddress}/sales-orders/renewal?salesperson=${salesperson}`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': `${salesperson}`,
				},
			});
			if (response.status === 200) {
				return response.data.results;
			}
		} catch (error) {
			console.error(error);
		}
	};

	const {
		data: salesOrders,
		error: salesOrdersError,
		isLoading: salesOrdersAreLoading,
	} = useQuery({
		queryKey: ['listSalespersonsSalesOrders', { selectedSalesperson }],
		queryFn: () => fetchUsersSalesOrders(selectedSalesperson),
		staleTime: 2 * 60 * 1000, // Cache data for 2 mins. User can clear cache by refreshing
		enabled: !!salespersons,
	});

	const months = [
		{ name: 'Jan', number: 1 },
		{ name: 'Feb', number: 2 },
		{ name: 'Mar', number: 3 },
		{ name: 'Apr', number: 4 },
		{ name: 'May', number: 5 },
		{ name: 'Jun', number: 6 },
		{ name: 'Jul', number: 7 },
		{ name: 'Aug', number: 8 },
		{ name: 'Sep', number: 9 },
		{ name: 'Oct', number: 10 },
		{ name: 'Nov', number: 11 },
		{ name: 'Dec', number: 12 },
	];

	function getMetricsForAllMonths(salesOrders) {
		return months.map(month => {
			const metrics = getMarkersForMonth(month.number, salesOrders);
			return {
				name: month.name,
				profit: metrics?.monthlyProfit || 0,
				cost: -1 * (metrics?.cogs || 0),
				commission: -1 * (metrics?.commission || 0),
			};
		});
	}

	const data = getMetricsForAllMonths(salesOrders);

	console.info(getMarkersForMonth(10)?.profit);

	return (
		<div className="animate-fade-in rounded-lg bg-white p-4 shadow-md transition-opacity duration-200 dark:bg-slate-700">
			<div className="flex flex-col">
				<div className="flex flex-col gap-4 dark:text-white text-black">
					<h2 className="text-xl font-bold">Salespersons</h2>
					<div className="w-1/4">
						<select
							className="block w-full rounded-xl border border-neutral-300 bg-white p-2.5 text-gray-700 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
							onChange={e => setSelectedSalesperson(e.target.value)}
							value={selectedSalesperson}
						>
							{salespersons?.map((salesperson, index) => (
								<option className="dark:bg-neutral-800" key={index} value={salesperson.full_name}>
									{salesperson.full_name}
								</option>
							))}
						</select>
					</div>
					<h3>Renewal Sales Orders</h3>
					<table>
						<thead>
							<tr className="text-left">
								<th className="px-1">Month</th>
								<th className="px-1">TCVs</th>
								<th className="px-1">Commission</th>
								<th className="px-1">Profit/Month</th>
								<th className="px-1">Profit/Year</th>
								<th className="px-1">Profit/TCVs</th>
							</tr>
						</thead>
						<tbody>
							{months.map(month => {
								const metrics = getMarkersForMonth(month.number, salesOrders);
								return (
									<tr key={`${month.number}-metrics`}>
										<td className="px-1">{month.number}</td>
										<td className="px-1">${formatMoney(metrics?.totalContractValues)}</td>
										<td className="px-1">${formatMoney(metrics?.commission)}</td>
										<td className="px-1">${formatMoney(metrics?.monthlyProfit)}</td>
										<td className="px-1">${formatMoney(metrics?.monthlyProfit * 12)}</td>
										<td className="px-1">${formatMoney(metrics?.profitTcvs)}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<BarChartStackBySign data={data} />
				</div>
			</div>
		</div>
	);
}
