/* eslint-disable no-unused-vars */
import { useState } from 'react';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ImDownload } from 'react-icons/im';
import { ImSearch } from 'react-icons/im';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useQuery } from 'react-query';
import axios from 'axios';
import { pdf } from '@react-pdf/renderer';
import { NavLink } from 'react-router-dom';
import ConsumptionReportPDF from '../consumptionReport/consumptionReportPDF';
import SalesOrderPDFBasic from '../salesOrder/SalesOrderPdfBasic';
import { SalesOrderPDFBasic as SalesOrderPDFBasicNCE } from '../SO_NCE_template_basic';
import { formatMoney } from '../../utils';

const currencyOptions = [
	{ value: 'USD', label: 'USD', symbol: '$', flag: '🇺🇸' },
	{ value: 'CAD', label: 'CAD', symbol: '$', flag: '🇨🇦' },
	{ value: 'GBP', label: 'GBP', symbol: '£', flag: '🇬🇧' },
	{ value: 'EUR', label: 'EUR', symbol: '€', flag: '🇪🇺' },
	{ value: 'CHF', label: 'CHF', symbol: 'Chf.', flag: '🇨🇭' },
	{ value: 'DKK', label: 'DKK', symbol: 'kr', flag: '🇩🇰' },
	{ value: 'NOK', label: 'NOK', symbol: 'kr', flag: '🇳🇴' },
	{ value: 'SEK', label: 'SEK', symbol: 'kr', flag: '🇸🇪' },
];

function DocumentList({
	activeSalesOrderId,
	activeCReportId,
	activeSalesOrderNceId,
	consumptionReports,
	salesOrders,
	salesOrdersNce,
	debouncedSearchInput,
	selectedMonth,
	selectedStatus,
	selectedSalesperson,
	renewalContinuationTokens,
	handleActiveSalesOrderChange,
	handleActiveConsumptionReportChange,
	handleActiveSalesOrderNceChange,
	accessToken,
	serverAddress,
	userName,
	activePage,
	handleNextPage,
	handlePrevPage,
}) {
	const [activeTab, setActiveTab] = useState('Sales Orders');
	// const [activePage, setactivePage] = useState(1);
	const [searchInput, setSearchInput] = useState('');
	const itemsPerPage = 10;

	//determine the documents to display based on the active tab
	const documents =
		activeTab === 'Sales Orders'
			? salesOrders || []
			: activeTab === 'Consumption Reports'
				? consumptionReports || []
				: salesOrdersNce || [];

	const fetchSalesOrderDetails = async activeSalesOrderId => {
		if (!activeSalesOrderId) return;

		const response = await axios.get(`${serverAddress}/sales-orders/renewal/${activeSalesOrderId}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
				'X-User-Name': `${userName}`,
			},
		});
		if (response.status === 200) {
			const data = response.data;
			console.log(data);
			const updatedPricingSummary = data.pricingSummary.map(item => {
				const unitPrice = item.unitPrice * 100 || 0;
				const quantity = item.quantity || 0;
				const currency = item.currency;
				const amount = unitPrice * quantity;
				return {
					...item,
					unit_price: unitPrice,
					currency: currency,
					amount: amount,
					effectiveStartDate: item.effectiveStartDate
						? new Date(item.effectiveStartDate).toLocaleDateString("en-US", { timeZone: 'UTC' })
						: null,
					effectiveEndDate: item.effectiveEndDate
						? new Date(item.effectiveEndDate).toLocaleDateString("en-US", { timeZone: 'UTC' })
						: null,
				};
			});

			return {
				...data,
				pricingSummary: updatedPricingSummary,
				totalContractValue: data.totalContractValue,
				validThrough: data.validThrough
					? new Date(data.validThrough)
					: null,
				renewalDate: data.renewalDate
					? new Date(data.renewalDate)
					: null,
			};
		}
		throw new Error('Failed to fetch sales order details');
	};

	const {
		data: salesOrderData,
		error: salesOrderError,
		isLoading: salesOrderIsLoading,
	} = useQuery({
		queryKey: ['salesOrderDetails', activeSalesOrderId],
		queryFn: () => fetchSalesOrderDetails(activeSalesOrderId),
		staleTime: 2 * 60 * 1000,
	});

	const fetchSalesOrderNCEDetails = async activeSalesOrderNceId => {
		if (!activeSalesOrderNceId) return;

		const response = await axios.get(`${serverAddress}/sales-orders/nce/${activeSalesOrderNceId}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
				'X-User-Name': `${userName}`,
			},
		});

		if (response.status === 200) {
			const data = response.data;
			console.log("FULL NCE SALES ORDER DATA ====>", data)
			const updatedPricingSummary = data.pricingSummary.map((item) => {
				const unitPrice = item.unitPrice || 0; //unitPrice has a default
				const quantity = item.quantity || 0; //quantity has a default
				const tttPrice = unitPrice; //tttPrice is directly mapped to unitPrice
				const tttTotal = unitPrice * quantity; //calculate total
				const effectiveStartDate = item.effectiveStartDate ? new Date(item.effectiveStartDate) : null;

				// Print effectiveStartDate for debugging
				console.log('Processed effectiveStartDate:', effectiveStartDate);

				return {
					...item,
					name: item.productName, //productName to name
					assignedSeats: quantity, //quantity to assignedSeats
					tttPrice, //calculate or map tttPrice
					tttTotal, //calculate tttTotal based on unitPrice and quantity
					effectiveStartDate: item.effectiveStartDate
						? new Date(item.effectiveStartDate).toLocaleDateString('en-US')
						: null, //NEED THIS IN BACKEND JSON SUMMARY
				};
			});

			return {
				...data,
				pricingSummary: updatedPricingSummary,
				validThrough: data.validThrough ? new Date(data.validThrough) : null,
				servicesStartDate: data.servicesStartDate ? new Date(data.servicesStartDate) : null,
				servicesEndDate: data.servicesEndDate ? new Date(data.servicesEndDate) : null,
			};
		}
		throw new Error('Failed to fetch Sales Order (Acq.) details');
	};

	const {
		data: salesOrderNCEData,
		error: salesOrderNCEError,
		isLoading: salesOrderNCEIsLoading,
	} = useQuery({
		queryKey: ['salesOrderNCEDetails', activeSalesOrderNceId],
		queryFn: () => fetchSalesOrderNCEDetails(activeSalesOrderNceId),
		staleTime: 2 * 60 * 1000,
	});

	const fetchConsumptionReportDetails = async (activeCReportId, accessToken, userName, serverAddress) => {
		if (!activeCReportId) return;

		// Log the URL for debugging
		console.log(`${serverAddress}/consumption-reports/${activeCReportId}`);

		const response = await axios.get(`${serverAddress}/consumption-reports/${activeCReportId}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
				'X-User-Name': `${userName}`,
			},
		});
		if (response.status === 200) {
			console.log('CR', response.data);
			return response.data;
		}
		throw new Error('Failed to fetch consumption report details');
	};

	//preview consumption report
	const handleCRPDFPreview = async reportDetails => {
		const blob = await pdf(
			<ConsumptionReportPDF
				rows={reportDetails.rows}
				clientName={reportDetails.clientName}
				//NEED KEY
				clientCompany={reportDetails.clientCompany}
				clientEmail={reportDetails.clientEmail}
				clientPhone={reportDetails.clientPhone}
				selectedSender={reportDetails.selectedSender}
				senderEmail={reportDetails.selectedSender?.email}
				senderPhone={reportDetails.selectedSender?.phone}
				totalCurrentVendor={reportDetails.rows[0]?.currentVendorTotal || 0}
				totalTtt={reportDetails.rows[0]?.tttTotal || 0}
				totalSavings={reportDetails.rows[0]?.lineItemSavings || 0}
				totalAnnualSavings={reportDetails.rows[0]?.lineItemSavings * 12 || 0}
				currency={reportDetails.currency}
				currentDate={new Date()}
				referenceCode={reportDetails.referenceCode}
				currentVendor={'Vendor'}
			/>
		).toBlob();

		const url = URL.createObjectURL(blob);
		const pdfUrlWithParams = `${url}#toolbar=0`;
		window.open(pdfUrlWithParams, '_blank');
	};

	//download consumption report
	const handleCRPDFDownload = async reportDetails => {
		const blob = await pdf(
			<ConsumptionReportPDF
				rows={reportDetails.rows}
				clientName={reportDetails.clientName}
				//NEED KEY
				clientCompany={reportDetails.clientCompany}
				clientEmail={reportDetails.clientEmail}
				clientPhone={reportDetails.clientPhone}
				selectedSender={reportDetails.selectedSender}
				senderEmail={reportDetails.selectedSender?.email}
				senderPhone={reportDetails.selectedSender?.phone}
				totalCurrentVendor={reportDetails.rows[0]?.currentVendorTotal || 0}
				totalTtt={reportDetails.rows[0]?.tttTotal || 0}
				totalSavings={reportDetails.rows[0]?.lineItemSavings || 0}
				totalAnnualSavings={reportDetails.rows[0]?.lineItemSavings * 12 || 0}
				currency={reportDetails.currency}
				currentDate={new Date()}
				referenceCode={reportDetails.referenceCode}
				currentVendor={'Vendor'}
			/>
		).toBlob();

		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = `Consumption_Report_${reportDetails.clientCompany}.pdf`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		URL.revokeObjectURL(url);
	};

	const handleSalesOrderPDFPreview = async salesOrderDetails => {
		const blob = await pdf(
			<SalesOrderPDFBasic
				formData={salesOrderDetails}
				addons={salesOrderDetails.pricingSummary}
				totalContractValue={salesOrderDetails.totalContractValue}
			/>
		).toBlob();

		const url = URL.createObjectURL(blob);
		window.open(url + '#toolbar=0', '_blank');
	};

	const handleSalesOrderPDFDownload = async salesOrderDetails => {
		const blob = await pdf(
			<SalesOrderPDFBasic
				formData={salesOrderDetails}
				addons={salesOrderDetails.pricingSummary}
				totalContractValue={salesOrderDetails.totalContractValue}
			/>
		).toBlob();

		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = `Sales_Order_${salesOrderDetails.companyName}.pdf`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		URL.revokeObjectURL(url);
	};

	const handleSalesOrderNcePDFPreview = async salesOrderNceDetails => {
		const blob = await pdf(
			<SalesOrderPDFBasicNCE formData={salesOrderNceDetails} addons={salesOrderNceDetails.pricingSummary} />
		).toBlob();

		const url = URL.createObjectURL(blob);
		window.open(url + '#toolbar=0', '_blank');
	};

	const handleSalesOrderNcePDFDownload = async salesOrderNceDetails => {
		const blob = await pdf(
			<SalesOrderPDFBasicNCE formData={salesOrderNceDetails} addons={salesOrderNceDetails.pricingSummary} />
		).toBlob();

		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = `SalesOrder_NCE_${salesOrderNceDetails.companyName || 'Unknown'}.pdf`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		URL.revokeObjectURL(link.href);
	};

	const copyToClipboard = id => {
		navigator.clipboard.writeText(id);
	};

	//convert epoch to PST date
	const pstDate = epoch => {
		const date = new Date(epoch * 1000); // Convert seconds to milliseconds

		const options = {
			timeZone: 'America/Los_Angeles',
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		};

		return date.toLocaleString('en-US', options);
	};

	const filteredDocuments = documents => {
		return documents.filter(document => {
			const searchValue = searchInput.trim().toLowerCase();
			return (
				document.clientCompany?.toLowerCase().includes(searchValue) ||
				document.companyName?.toLowerCase().includes(searchValue) ||
				document.id?.toLowerCase().includes(searchValue)
			);
		});
	};

	//filter logic for documents based on search input
	const searchFilter = (document, isSalesOrder) => {
		const searchValue = debouncedSearchInput.trim().toLowerCase();

		if (isSalesOrder) {
			//sales Order filtering
			if (searchValue.length === 17 || searchValue.length === 16) {
				return document.chargebeeSubscriptionId?.toLowerCase().includes(searchValue);
			} else if (searchValue.length === 36) {
				return document.salesOrderId?.toLowerCase().includes(searchValue);
			} else if (searchValue.length > 2) {
				return document.companyName?.toLowerCase().includes(searchValue);
			} else {
				return true;
			}
		} else {
			//consumption Report filtering
			if (searchValue.length > 2) {
				return document.clientCompany?.toLowerCase().includes(searchValue);
			} else {
				return true;
			}
		}
	};

	//date filter
	const dateFilter = document => {
		const date = pstDate(document?._ts);
		const month = date.split('/')[0];
		if (month === selectedMonth) {
			return document;
		} else if (selectedMonth === 'All Time' || selectedMonth === '') {
			return document;
		}
	};

	//status filter (for sales orders only)
	const statusFilter = (document, isSalesOrder) => {
		if (!isSalesOrder) return document; //no status filter for consumption reports
		if (selectedStatus === 'All Statuses') {
			return document;
		} else if (document?.status) {
			return document.status === selectedStatus;
		}
	};

	//salesperson filter
	const salespersonFilter = (document, isSalesOrder) => {
		if (selectedSalesperson === 'All Salespersons') {
			return document;
		} else if (isSalesOrder && document?.salesPersonName) {
			return document.salesPersonName === selectedSalesperson;
		} else if (!isSalesOrder && document?.selectedSender?.full_name) {
			return document.selectedSender.full_name === selectedSalesperson;
		}
	};

	//handle next page navigation
	// const handleNextPage = () => {
	// 	if (activePage * itemsPerPage < documents.length) {
	// 		setactivePage(activePage + 1);
	// 	}
	// };

	//handle previous page navigation
	// const handlePreviousPage = () => {
	// 	if (activePage > 1) {
	// 		setactivePage(activePage - 1);
	// 	}
	// };

	//render document list
	const renderDocumentList = (documents, isSalesOrder) => {
		return filteredDocuments(documents)
			.filter(document => searchFilter(document, isSalesOrder))
			.filter(dateFilter)
			.filter(document => statusFilter(document, isSalesOrder))
			.filter(document => salespersonFilter(document, isSalesOrder))
			.sort((a, b) => b._ts - a._ts)
			.slice((activePage.page - 1) * itemsPerPage, activePage.page * itemsPerPage)
			.map((document, index) => {
				return (
					<div
						key={index}
						className={`flex justify-between items-center p-2 mb-1 rounded-lg cursor-pointer transition-all duration-150 
							${(
								isSalesOrder && activeTab === "Sales Orders"
									? activeSalesOrderId === document.id
									: activeTab === "Sales Orders NCE"
										? activeSalesOrderNceId ===
										document.id
										: activeCReportId === document.id
							)
								? "bg-blue-400 text-white"
								: "odd:bg-gray-50 even:bg-gray-100 hover:bg-gray-200 dark:odd:bg-gray-800 text-black dark:text-white dark:even:bg-gray-700 dark:hover:bg-gray-600"
							}`}
						onClick={() => {
							if (activeTab === 'Sales Orders') {
								handleActiveSalesOrderChange(document.id);
							} else if (activeTab === 'Consumption Reports') {
								handleActiveConsumptionReportChange(document.id);
							} else if (activeTab === 'Sales Orders NCE') {
								handleActiveSalesOrderNceChange(document.id);
							}
						}}
					>
						{/* Document ID */}
						<div className="w-1/3 flex items-center text-left">
							<p className="truncate w-28 mr-2">
								{isSalesOrder
									? `${document?.id?.slice(0, 8)}...` ||
									"No ID"
									: `${document?.id?.slice(0, 8)}...` ||
									"No Report ID"}
							</p>
							<button
								className="text-blue-500 hover:text-blue-700"
								onClick={e => {
									e.stopPropagation(); // Prevent triggering row click
									copyToClipboard(isSalesOrder ? document?.id : document?.id);
								}}
							>
								<ContentCopyIcon fontSize="small" />
							</button>
						</div>

						{/* Company Name */}
						<p className="w-1/3 text-left">
							{isSalesOrder
								? document?.companyName || 'Unknown Company'
								: document?.clientCompany || 'Unknown Company'}
						</p>

						{isSalesOrder && document.totalContractValue !== undefined && (
							<p className="w-1/3 text-left">
								{currencyOptions.find(option => option.value === document.currency)?.symbol}{' '}
								{formatMoney(document.totalContractValue)}
							</p>
						)}

						{/* Document Date */}
						<p className="w-1/3 text-left">{pstDate(document?._ts)}</p>
						{(activeTab === 'Sales Orders NCE' || activeTab === 'Sales Orders') && (
							<p className="w-1/3 text-left">
								<span
									className={`
										px-2 py-1 rounded-md text-white text-sm
										${document.status === 'Draft' ? 'bg-gray-400' : ''}
										${document.status === 'Pending' ? 'bg-yellow-400 text-black' : ''}
										${document.status === 'Approved' ? 'bg-green-500' : ''}
										${document.status === 'Signed' ? 'bg-green-500' : ''}
										${document.status === 'Rejected' ? 'bg-red-500' : ''}
									`}
								>
									{document.status || 'N/A'}
								</span>
							</p>
						)}
					</div>
				);
			});
	};

	return (
		<div className="w-full h-[66vh] p-4 dark:border-gray-600 border-gray-300 shadow-mdrounded-lg">
			{/* Tabs */}
			<div className="my-6 flex justify-start gap-3 text-black dark:text-white mb-3 bg-gray-500/10 rounded-t">
				<button
					onClick={() => setActiveTab('Sales Orders')}
                    className={`p-3 cursor-pointer text-md ${activeTab === 'Sales Orders' ? 'border-b-2 border-blue-500 text-blue-500' : ''}`}
				>
					Sales Orders (Renewal)
				</button>
				<button
					onClick={() => setActiveTab('Consumption Reports')}
                    className={`p-3 cursor-pointer text-md ${activeTab === 'Consumption Reports' ? 'border-b-2 border-blue-500 text-blue-500' : ''}`}
				>
					Consumption Reports
				</button>
				<button
					onClick={() => setActiveTab('Sales Orders NCE')}
                    className={`p-3 cursor-pointer text-md ${activeTab === 'Sales Orders NCE' ? 'border-b-2 border-blue-500 text-blue-500' : ''}`}
				>
					Sales Orders (Acq.)
				</button>
			</div>

			{/* BUTTONS */}
			<div className="flex justify-between items-center mb-4">
				<input
					type="text"
					placeholder="Search by Company or Document ID..."
					value={searchInput}
					onChange={e => setSearchInput(e.target.value)}
					className="border border-gray-300 dark:border-gray-600 px-4 py-2 rounded-lg w-1/3 text-black dark:text-white bg-white dark:bg-gray-800"
				/>
				<div className="flex">
					{/* Show Create Sales Order button only when the active tab is Consumption Reports */}
					{activeTab === 'Consumption Reports' && (
						<NavLink
							className="mr-2 bg-black text-white px-2 py-1 rounded hover:bg-gray-800 text-sm transition-all duration-150"
							to="/sales-orders-nce"
							state={{ activeCReportId }}
						>
							<ReceiptIcon className="inline-block mr-1" /> Create Sales Order
						</NavLink>
					)}

					{/* Sales Orders (NOT NCE) */}
					{activeTab === 'Sales Orders' && (
						<>
							<NavLink
								className="mr-2 bg-black text-white px-2 py-1 rounded hover:bg-gray-800 text-sm transition-all duration-150"
								to="/sales-orders"
								state={{ activeSalesOrderId }}
								onClick={e => {
									if (!activeSalesOrderId) {
										e.preventDefault();
										alert('Please select a Sales Order before editing.');
									}
								}}
							>
								<ModeEditIcon className="inline-block mr-1" /> Edit Sales Order
							</NavLink>

							<button
								className="mr-2 bg-black text-white px-2 py-1 rounded hover:bg-gray-800 text-sm transition-all duration-150"
								onClick={async () => {
									if (activeSalesOrderId) {
										try {
											const details = await fetchSalesOrderDetails(activeSalesOrderId);
											await handleSalesOrderPDFDownload(details);
										} catch (error) {
											console.error('Error fetching sales order details:', error);
										}
									}
								}}
							>
								<ImDownload className="inline-block mr-1" /> Download
							</button>

							<button
								className="mr-2 bg-black text-white px-2 py-1 rounded hover:bg-gray-800 text-sm transition-all duration-150"
								onClick={async () => {
									if (activeSalesOrderId) {
										try {
											const details = await fetchSalesOrderDetails(activeSalesOrderId);
											await handleSalesOrderPDFPreview(details);
										} catch (error) {
											console.error('Error fetching sales order details:', error);
										}
									}
								}}
							>
								<ImSearch className="inline-block mr-1" /> Preview
							</button>
						</>
					)}

					{activeTab === 'Sales Orders NCE' && (
						<>
							<NavLink
								className="mr-2 bg-black text-white px-2 py-1 rounded hover:bg-gray-800 text-sm transition-all duration-150"
								to="/sales-orders-nce"
								state={{ activeSalesOrderNceId }}
								onClick={e => {
									if (!activeSalesOrderNceId) {
										e.preventDefault();
										alert('Please select a Sales Order (Acq.) before editing.');
									}
								}}
							>
								<ModeEditIcon className="inline-block mr-1" /> Edit Sales Order
							</NavLink>

							<button
								className="mr-2 bg-black text-white px-2 py-1 rounded hover:bg-gray-800 text-sm transition-all duration-150"
								onClick={async () => {
									if (activeSalesOrderNceId) {
										try {
											const details = await fetchSalesOrderNCEDetails(activeSalesOrderNceId);
											await handleSalesOrderNcePDFDownload(details);
										} catch (error) {
											console.error('Error fetching Sales Order (Acq.) details:', error);
										}
									}
								}}
							>
								<ImDownload className="inline-block mr-1" /> Download
							</button>

							<button
								className="mr-2 bg-black text-white px-2 py-1 rounded hover:bg-gray-800 text-sm transition-all duration-150"
								onClick={async () => {
									if (activeSalesOrderNceId) {
										try {
											const details = await fetchSalesOrderNCEDetails(activeSalesOrderNceId);
											await handleSalesOrderNcePDFPreview(details);
										} catch (error) {
											console.error('Error fetching Sales Order (Acq.) details:', error);
										}
									}
								}}
							>
								<ImSearch className="inline-block mr-1" /> Preview
							</button>
						</>
					)}

					{/* Buttons for Consumption Reports */}
					{activeTab === 'Consumption Reports' && (
						<>
							<button
								className="mr-2 bg-black text-white px-2 py-1 rounded hover:bg-gray-800 text-sm transition-all duration-150"
								onClick={async () => {
									if (activeCReportId) {
										try {
											const details = await fetchConsumptionReportDetails(
												activeCReportId,
												accessToken,
												userName,
												serverAddress
											);
											await handleCRPDFDownload(details);
										} catch (error) {
											console.error('Error fetching report details:', error);
										}
									}
								}}
							>
								<ImDownload className="inline-block mr-1" /> Download
							</button>

							<button
								className="mr-2 bg-black text-white px-2 py-1 rounded hover:bg-gray-800 text-sm transition-all duration-150"
								onClick={async () => {
									if (activeCReportId) {
										try {
											const details = await fetchConsumptionReportDetails(
												activeCReportId,
												accessToken,
												userName,
												serverAddress
											);
											await handleCRPDFPreview(details);
										} catch (error) {
											console.error('Error fetching report details:', error);
										}
									}
								}}
							>
								<ImSearch className="inline-block mr-1" /> Preview
							</button>

							<NavLink
								className="bg-black text-white px-2 py-1 rounded hover:bg-gray-800 text-sm transition-all duration-150"
								to="/consumption-reports"
								state={{ activeCReportId }}
							>
								<ModeEditIcon className="inline-block mr-1" /> Edit
							</NavLink>
						</>
					)}
				</div>
			</div>

			{/* Pagination */}
			<div className="flex justify-between items-center mt-4">
				<button
					onClick={() => handlePrevPage(activeTab)}
					className="px-4 py-2 text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100"
					disabled={activePage.page === 1}
				>
					<KeyboardDoubleArrowLeftIcon />
					Previous
				</button>
				<span className="text-gray-600 dark:text-gray-300">{activePage.page}</span>
				<button
					onClick={() => handleNextPage(activeTab)}
					className="px-4 py-2 text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100"
					// disabled={activePage * itemsPerPage >= documents.length}
				>
					Next
					<KeyboardDoubleArrowRightIcon />
				</button>
			</div>

			{/* Table Columns */}
			<div className="flex mb-4 text-sm font-medium text-gray-600 dark:text-gray-300">
				<p className="w-1/3">ID</p>
				<p className="w-1/3">Company</p>
				{activeTab === 'Sales Orders NCE' ? (
					<>
						<p className="w-1/3">Total Contract Value</p>
						<p className="w-1/3">Last Updated</p>
						<p className="w-1/3">Status</p>
					</>
				) : activeTab === 'Sales Orders' ? (
					<>
						<p className="w-1/3">Total Contract Value</p>
						<p className="w-1/3">Last Updated</p>
						<p className="w-1/3">Status</p>
					</>
				) : (
					<>
						<p className="w-1/3">Last Updated</p>
					</>
				)}
			</div>

			<ul>
				{activeTab === 'Sales Orders' && salesOrders?.length > 0 ? (
					renderDocumentList(salesOrders, true)
				) : activeTab === 'Consumption Reports' && consumptionReports?.length > 0 ? (
					renderDocumentList(consumptionReports, false)
				) : activeTab === 'Sales Orders NCE' && salesOrdersNce?.length > 0 ? (
					renderDocumentList(salesOrdersNce, true)
				) : (
					<div className="flex justify-center items-center dark:text-white text-black">
						No {activeTab.toLowerCase()} found for {selectedSalesperson}!
					</div>
				)}
			</ul>
		</div>
	);
}

export default DocumentList;
