import { useEffect } from 'react';
import App from './App.jsx';
import './index.css';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import AuthProvider from './context/AuthContext.jsx';
import { BrowserRouter } from 'react-router-dom';
import { DarkModeProvider } from './context/DarkModeContext.jsx';
import { SettingsProvider } from './context/SettingsContext.jsx';

export const msalInstance = new PublicClientApplication(msalConfig);

const AppWrapper = () => {
	useEffect(() => {
		const initializeMsal = async () => {
			await msalInstance.initialize();

			// Default to using the first account if no account is active on page load
			if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
				// Account selection logic is app dependent. Adjust as needed for different use cases.
				msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
			}

			// Optional - This will update account state if a user signs in from another tab or window
			msalInstance.enableAccountStorageEvents();

			msalInstance.addEventCallback(event => {
				if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
					const account = event.payload.account;
					msalInstance.setActiveAccount(account);
				}
			});
		};

		initializeMsal();
	}, []);

	const queryClient = new QueryClient();

	return (
		<MsalProvider instance={msalInstance}>
			<QueryClientProvider client={queryClient}>
				<AuthProvider>
					<DarkModeProvider>
						<BrowserRouter>
							<SettingsProvider>
								<App pca={msalInstance} />
							</SettingsProvider>
						</BrowserRouter>
					</DarkModeProvider>
				</AuthProvider>
			</QueryClientProvider>
		</MsalProvider>
	);
};

export default AppWrapper;
