import { useEffect, useState } from 'react';

// Input value is Generic <T> type (can be number, string, object, etc.)
// Delay is a number in milliseconds
// Returns a debounced value of the input value, after the delay has passed
// Useful for removing need for a "Confirm" or "Search" button in search bars, among other onChange events

function useDebounce(inputValue, delay) {
	const [debouncedValue, setDebouncedValue] = useState(inputValue);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(inputValue);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [inputValue, delay]);

	return debouncedValue;
}

export default useDebounce;
