import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine } from 'recharts';

export default function BarChartStackBySign({ data }) {
	return (
		<BarChart
			width={1000}
			height={500}
			data={data}
			stackOffset="sign"
			margin={{
				top: 5,
				right: 30,
				left: 20,
				bottom: 5,
			}}
		>
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis dataKey="name" />
			<YAxis />
			<Tooltip />
			<Legend />
			<ReferenceLine y={0} stroke="#000" />
			<Bar dataKey="profit" fill="#8884d8" stackId="stack" />
			<Bar dataKey="cost" fill="#82ca9d" stackId="stack" />
			{/* <Bar dataKey="commission" fill="#ffa645" stackId="stack" /> */}
		</BarChart>
	);
}
