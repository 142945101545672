/* eslint-disable no-unused-vars */
import { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { formatMoney } from "../../utils";
// import { BarChart } from '@mui/x-charts/BarChart';
// import { axisClasses } from "@mui/x-charts/ChartsAxis";

const currencyOptions = [
	{ value: 'USD', label: 'USD', symbol: '$', flag: '🇺🇸' },
	{ value: 'CAD', label: 'CAD', symbol: '$', flag: '🇨🇦' },
	{ value: 'GBP', label: 'GBP', symbol: '£', flag: '🇬🇧' },
	{ value: 'EUR', label: 'EUR', symbol: '€', flag: '🇪🇺' },
	{ value: 'CHF', label: 'CHF', symbol: 'Chf.', flag: '🇨🇭' },
	{ value: 'DKK', label: 'DKK', symbol: 'kr', flag: '🇩🇰' },
	{ value: 'NOK', label: 'NOK', symbol: 'kr', flag: '🇳🇴' },
	{ value: 'SEK', label: 'SEK', symbol: 'kr', flag: '🇸🇪' },
];

function DealList({
	activeSalesOrderId,
	activeSalesOrderNceId,
	salesOrders = [],
	salesOrdersNce = [],
	debouncedSearchInput,
	salesOrderData,
	salesOrderNCEData,
	selectedMonth,
	selectedStatus,
	selectedSalesperson,
	handleActiveSalesOrderChange,
	handleActiveSalesOrderNceChange,
	accessToken,
	serverAddress,
	userName,
}) {
	const itemsPerPage = 10;
	const [currentPage, setCurrentPage] = useState(1);

	const filteredDocuments = documents => {
		return documents.filter(document => {
			const searchValue = debouncedSearchInput.trim().toLowerCase();
			return (
				document.companyName?.toLowerCase().includes(searchValue) ||
				document.id?.toLowerCase().includes(searchValue)
			);
		});
	};

	const renderDocumentList = (documents, isNce = false) => {
		return filteredDocuments(documents)
			.sort((a, b) => b._ts - a._ts)
			.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
			.map((document, index) => {
				const currencySymbol = currencyOptions.find(option => option.value === document.currency)?.symbol || '';

				console.log('Document:', document);
				const grossProfit = (document.monthlyProfit || 0) * (document.termMonths || 0);

				const grossProfitClass = grossProfit >= 0 ? 'text-green-500' : 'text-red-500';

				return (
					<div
						key={index}
						className="flex justify-between items-center p-1 mb-0.5 rounded-lg cursor-pointer transition-all duration-150 
						odd:bg-gray-50 even:bg-gray-100 hover:bg-gray-200 dark:odd:bg-gray-800 text-black dark:text-white dark:even:bg-gray-700 dark:hover:bg-gray-600"
						style={{ height: '32px' }}
						onClick={() => {
							if (isNce) {
								handleActiveSalesOrderNceChange(document.id);
							} else {
								handleActiveSalesOrderChange(document.id);
							}
						}}
					>
						<p className="w-1/5 px-4 truncate text-sm">{document.companyName || 'Unknown Company'}</p>
						<p className="w-1/5 pl-8 text-center text-sm">{document.termMonths || 'N/A'}</p>
						<p className="w-1/5 pl-8 text-left text-sm">
							{currencySymbol}{' '}
							{document.totalContractValue ? document.totalContractValue.toLocaleString() : 'N/A'}
						</p>
						<p className="w-1/5 pl-8 text-left text-sm">{document.salesPersonName || 'N/A'}</p>

						{/* Discount % */}
						<p className={`w-1/5 pl-8 text-left text-sm ${grossProfitClass}`}>
						{currencySymbol}{" "}{formatMoney(grossProfit)}
						</p>

						<p className="w-1/5 pl-8 text-left text-sm">
							<span
								className={`px-2 py-0.5 rounded-md text-white text-sm ${document.status === 'Draft' ? 'bg-gray-400' : document.status === 'Approved' ? 'bg-green-500' : document.status === 'Pending' ? 'bg-yellow-500' : 'bg-red-500'}`}
							>
								{document.status || 'N/A'}
							</span>
						</p>

						<p className="w-1/5 pl-8 text-left text-sm">{new Date(document._ts * 1000).toLocaleString()}</p>
					</div>
				);
			});
	};

	return (
		<div className="w-full h-[86vh] flex flex-col justify-between ">
			<div className="">
				{/* Table Header */}
				<div className="flex mb-2 text-sm font-semibold text-gray-600 dark:text-gray-300">
					<p className="w-1/5 px-4 text-left">Company</p>
					<p className="w-1/5 px-8 text-right">Net Terms (Months)</p>
					<p className="w-1/5 px-8 text-left">Total Contract Value</p>
					<p className="w-1/5 px-8 text-left">Salesperson</p>
					<p className="w-1/5 px-8 text-left">Gross Profit</p> {/* New Discount column */}
					<p className="w-1/5 px-8 text-left">Status</p>
					<p className="w-1/5 px-8 text-left">Last Modified</p>
				</div>

				{/* Table Rows */}
				<div>
					<h3 className="text-lg font-bold mb-2 text-black dark:text-white">Renewal</h3>
					<ul>{salesOrders.length > 0 && renderDocumentList(salesOrders)}</ul>
				</div>

				<div className="mt-6">
					<h3 className="text-lg font-bold mb-2 text-black dark:text-white">Acquisition</h3>
					<ul>{salesOrdersNce.length > 0 && renderDocumentList(salesOrdersNce, true)}</ul>
				</div>
			</div>

			{/* Pagination Controls */}
			<div className="flex justify-between items-center mt-4">
				<button
					onClick={() => setCurrentPage(currentPage - 1)}
					disabled={currentPage === 1}
					className="px-4 py-2 text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100"
				>
					Previous
				</button>
				<span className="text-gray-600 dark:text-gray-300">{currentPage}</span>
				<button
					onClick={() => setCurrentPage(currentPage + 1)}
					disabled={currentPage * itemsPerPage >= salesOrders.length + salesOrdersNce.length}
					className="px-4 py-2 text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100"
				>
					Next
				</button>
			</div>
		</div>
	);
}

export default DealList;
