function LogoLoading() {
	return (
		<div className="flex-col gap-4 w-full flex items-center justify-center">
			<div className="w-24 h-24 border-8 text-green-400 text-4xl border-gray-500/30 flex items-center justify-center border-t-[#60c659] rounded-full animate-pulse-spin">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					version="1.1"
					id="Layer_1"
					x="0px"
					y="0px"
					viewBox="0 0 3296.6 2783.8"
					style={{ enableBackground: 'new 0 0 3296.6 2783.8' }}
					xmlSpace="preserve"
					className="w-6 h-6 animate-scale-fade"
				>
					<style type="text/css">
						{`
                            .st0 { fill: #60C659; }
                            .st1 { fill: #a1a1a178; }
                        `}
					</style>
					<g>
						<g>
							<g>
								<g>
									<g>
										<polygon
											className="st0"
											points="0,0 0,812.8 259.7,812.8 520.9,812.8 520.9,1091.1 520.9,2554.2 1556.2,2783.8 1556.2,812.8 2077.2,812.8 2077.2,0"
										/>
									</g>
									<g>
										<polygon
											className="st1"
											points="2329.2,0 2329.2,669.6 2329.2,1091.1 2329.2,1091.1 2329.1,1091.1 1815.9,1091.1 1815.9,2783.4 2775.6,2570.4 2775.6,1145.1 2775.6,1091.1 2775.6,1091.1 2775.7,1091.1 3296.6,1091.1 3296.6,0"
										/>
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>
			</div>
		</div>
	);
}

export default LogoLoading;
