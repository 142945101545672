export default function ConfirmModal({ isOpen, message, onClose, onConfirm }) {
	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
			<div className="bg-neutral-200 dark:bg-slate-600 rounded-lg p-6 w-[500px]">
				<h2 className="text-lg font-semibold mb-4">Are you sure?</h2>
				<p className="mb-6">{message}</p>
				<div className="flex justify-end space-x-4">
					<button
						className="bg-gray-500 dark:bg-gray-300 hover:bg-gray-400 text-white dark:text-gray-800 py-2 px-4 rounded"
						onClick={onClose}
					>
						Cancel
					</button>
					<button className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded" onClick={onConfirm}>
						Confirm
					</button>
				</div>
			</div>
		</div>
	);
}
