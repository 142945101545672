/* eslint-disable react-refresh/only-export-components */
import { createContext, useContext, useState, useEffect } from 'react';

const DarkModeContext = createContext();

export const useDarkMode = () => useContext(DarkModeContext);

export function DarkModeProvider({ children }) {
	const [darkMode, setDarkMode] = useState(() => {
		const storedMode = localStorage.getItem('dark-mode');
		return storedMode === 'true';
	});

	useEffect(() => {
		localStorage.setItem('dark-mode', darkMode);
		if (darkMode) {
			document.documentElement.classList.add('dark');
		} else {
			document.documentElement.classList.remove('dark');
		}
	}, [darkMode]);

	return <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>{children}</DarkModeContext.Provider>;
}
