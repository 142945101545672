/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import axios from 'axios';
import useDebounce from '../hooks/useDebounce';
import { useQuery } from 'react-query';
import DocumentList from '../components/myDocuments/documentList';
import DealList from '../components/dealdesk/dealList';
import { serverAddress } from '../authConfig';
import { useAuth } from '../context/AuthContext';

function MyDealDesk() {
	const { user, accessToken } = useAuth();

	const [selectedSalesperson, setSelectedSalesperson] = useState(user?.userName);
	const [selectedMonth, setSelectedMonth] = useState('All Time');
	const [searchInput, setSearchInput] = useState('');
	const [selectedStatus, setSelectedStatus] = useState('All Statuses');
	const [activeSalesOrderId, setActiveSalesOrderId] = useState('');
	const [activeSalesOrderNceId, setActiveSalesOrderNceId] = useState('');
	const debouncedSearchInput = useDebounce(searchInput, 500);

	const [salesOrders, setSalesOrders] = useState([]);
	const [salesOrdersNce, setSalesOrdersNce] = useState([]);

	const [salesOrderContinuationToken, setSalesOrderContinuationToken] = useState('');
	const [salesOrderNceContinuationToken, setSalesOrderNceContinuationToken] = useState('');

	//fetch AEs and AMs
	const fetchSalespersons = async () => {
		// AMs/AEs should only be able to see their own sales orders if no elevated permissions
		if (!user?.isElevatedPermissions) return [{ full_name: user?.userName }];

		try {
			//fetch AMs
			const amResponse = await axios.get(`${serverAddress}/salespersons/account-managers`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': `${user?.userName}`,
				},
			});

			//fetch AEs
			const aeResponse = await axios.get(`${serverAddress}/salespersons/account-executives`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': `${user?.userName}`,
				},
			});

			if (amResponse.status === 200 && aeResponse.status === 200) {
				//combine both AMs and AEs
				const combinedSalespersons = [...amResponse.data.salespersons, ...aeResponse.data.salespersons];
				return combinedSalespersons;
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		setSalesOrderContinuationToken('');
		setSalesOrders([]);
		setSalesOrderNceContinuationToken('');
		setSalesOrdersNce([]);
	}, [selectedSalesperson]);

	//fetch sales orders based on the selected Salesperson
	const fetchUsersSalesOrders = async salesperson => {
		if (salesperson === undefined) return;

		setSelectedSalesperson(salesperson);
		try {
			const response = await axios.get(`${serverAddress}/sales-orders/renewal?salesperson=${salesperson}`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': `${salesperson}`,
				},
			});
			if (response.status === 200) {
				setSalesOrderContinuationToken(response.data.continuation_token);
				if (response.data.results) {
					setSalesOrders(prev => [...prev, ...response.data.results]);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	//fetch sales orders based on the selected Salesperson
	const fetchUsersSalesOrdersNCE = async salesperson => {
		if (salesperson === undefined) return;

		setSelectedSalesperson(salesperson);
		try {
			const response = await axios.get(`${serverAddress}/sales-orders/nce?salesperson=${salesperson}`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': `${salesperson}`,
				},
			});
			if (response.status === 200) {
				setSalesOrderNceContinuationToken(response.data.continuation_token);
				if (response.data.results) {
					setSalesOrdersNce(prev => [...prev, ...response.data.results]);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	//useQuery to get both AMs and AEs
	const {
		data: salespersons,
		error: salespersonsError,
		isLoading: salespersonsIsLoading,
	} = useQuery({
		queryKey: ['salespersons'],
		queryFn: () => fetchSalespersons(),
		staleTime: 30 * 60 * 1000,
	});

	//useQuery to fetch sales orders for the selected Salesperson
	const { error: salesOrdersError, isLoading: salesOrdersAreLoading } = useQuery({
		queryKey: ['listSalespersonsSalesOrders', selectedSalesperson, salesOrderContinuationToken],
		queryFn: () => fetchUsersSalesOrders(selectedSalesperson, salesOrderContinuationToken),
		refetchOnWindowFocus: false,
		staleTime: 2 * 60 * 1000,
		enabled: !!salespersons,
	});

	//useQuery to fetch sales orders NCE for the selected Salesperson
	const { error: salesOrdersNceError, isLoading: salesOrdersNceAreLoading } = useQuery({
		queryKey: ['listSalespersonsSalesOrdersNce', { selectedSalesperson }],
		queryFn: () => fetchUsersSalesOrdersNCE(selectedSalesperson),
		refetchOnWindowFocus: false,
		staleTime: 2 * 60 * 1000,
		enabled: !!salespersons,
	});

	const fetchSalesOrderDetails = async activeSalesOrderId => {
		if (!activeSalesOrderId) return;

		const response = await axios.get(`${serverAddress}/sales-orders/renewal/${activeSalesOrderId}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
				'X-User-Name': `${user?.userName}`,
			},
		});

		if (response.status === 200) {
			const data = response.data;
			console.log('Fetched Renewal Order Data: ', data);
			const termMonths = data.termMonths || 0;
			const totalByMatchedUnitPrice =
				data.pricingSummary.reduce((acc, item) => {
					const matchedUnitPrice = item.matchedUnitPrice || 0;
					const quantity = item.quantity || 0;
					return acc + matchedUnitPrice * quantity;
				}, 0) * termMonths;

			const totalContractValue = data.totalContractValue || 0;

			const discountPercentage =
				totalByMatchedUnitPrice > 0
					? ((totalByMatchedUnitPrice - totalContractValue) / totalByMatchedUnitPrice) * 100
					: 0;

			return {
				...data,
				totalByMatchedUnitPrice,
				discountPercentage: discountPercentage < 0 ? discountPercentage : 0,
				pricingSummary: data.pricingSummary.map(item => ({
					...item,
					unit_price: item.unitPrice * 100 || 0,
					amount: item.unitPrice * item.quantity || 0,
				})),
			};
		}

		throw new Error('Failed to fetch sales order details');
	};

	const {
		data: salesOrderData,
		error: salesOrderError,
		isLoading: salesOrderIsLoading,
	} = useQuery({
		queryKey: ['salesOrderDetails', activeSalesOrderId],
		queryFn: () => fetchSalesOrderDetails(activeSalesOrderId),
		staleTime: 2 * 60 * 1000,
		enabled: !!activeSalesOrderId, //only fetch if there is an active ID
	});

	//FIX ME: UNABLE TO FETCH NCE (MISSING CB SUB ID....)
	const fetchSalesOrderNCEDetails = async activeSalesOrderNceId => {
		if (!activeSalesOrderNceId) return;

		const response = await axios.get(`${serverAddress}/sales-orders/nce/${activeSalesOrderNceId}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
				'X-User-Name': `${user?.userName}`,
			},
		});

		if (response.status === 200) {
			const data = response.data;

			const termMonths = data.termMonths || 0;
			const totalByMatchedUnitPrice =
				data.pricingSummary.reduce((acc, item) => {
					const matchedUnitPrice = item.matchedUnitPrice || 0;
					const quantity = item.quantity || 0;
					return acc + matchedUnitPrice * quantity;
				}, 0) * termMonths;

			const totalContractValue = data.totalContractValue || 0;

			const discountPercentage =
				totalByMatchedUnitPrice > 0
					? ((totalByMatchedUnitPrice - totalContractValue) / totalByMatchedUnitPrice) * 100
					: 0;

			return {
				...data,
				totalByMatchedUnitPrice,
				discountPercentage: discountPercentage < 0 ? discountPercentage : 0, //negative percentage means discount
				pricingSummary: data.pricingSummary.map(item => ({
					...item,
					assignedSeats: item.quantity || 0,
					tttPrice: item.unitPrice || 0,
					tttTotal: item.unitPrice * item.quantity || 0,
				})),
			};
		}

		throw new Error('Failed to fetch Sales Order (Acq.) details');
	};

	const {
		data: salesOrderNCEData,
		error: salesOrderNCEError,
		isLoading: salesOrderNCEIsLoading,
	} = useQuery({
		queryKey: ['salesOrderNCEDetails', activeSalesOrderNceId],
		queryFn: () => fetchSalesOrderNCEDetails(activeSalesOrderNceId),
		staleTime: 2 * 60 * 1000,
		enabled: !!activeSalesOrderNceId,
	});

	return (
		<div>
			<DealList
				activeSalesOrderId={activeSalesOrderId}
				activeSalesOrderNceId={activeSalesOrderNceId}
				salesOrders={salesOrders}
				salesOrdersNce={salesOrdersNce}
				debouncedSearchInput={debouncedSearchInput}
				selectedMonth={selectedMonth}
				selectedStatus={selectedStatus}
				salesOrderData={salesOrderData}
				salesOrderNCEData={salesOrderNCEData}
				selectedSalesperson={selectedSalesperson}
				handleActiveSalesOrderChange={salesOrderId => {
					setActiveSalesOrderId(salesOrderId);
				}}
				handleActiveSalesOrderNceChange={salesOrderNceId => {
					setActiveSalesOrderNceId(salesOrderNceId);
				}}
				serverAddress={serverAddress}
				accessToken={accessToken}
				userName={user?.userName}
			/>
		</div>
	);
}

export default MyDealDesk;
