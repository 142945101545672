import axios from 'axios';
import { formatMoney } from '../../utils';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useDarkMode } from '../../context/DarkModeContext';
import { useState } from 'react';
import ConfirmModal from '../ConfimModal';
import LogoLoading from '../common/LogoLoading';

function CommissionDetails({ activeSalesOrderId, serverAddress, accessToken, userName, selectedSalesperson }) {
	const { darkMode } = useDarkMode();
	const queryClient = useQueryClient();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [status, setStatus] = useState(null);

	const fetchSalesOrderDetails = async () => {
		if (!activeSalesOrderId) return null;
		const response = await axios.get(`${serverAddress}/sales-orders/renewal/${activeSalesOrderId}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
				'X-User-Name': userName,
			},
		});
		return response.data;
	};

	const {
		data: salesOrder,
		isLoading,
		error,
	} = useQuery(['salesOrderDetails', activeSalesOrderId], fetchSalesOrderDetails, {
		enabled: !!activeSalesOrderId,
		staleTime: 2 * 60 * 1000,
	});

	const mutation = useMutation({
		mutationFn: async ({ status, id }) => {
			await axios.patch(
				`${serverAddress}/sales-orders/renewal/status`,
				{ id, status, user: userName, updated: Date.now() },
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
						'X-User-Name': userName,
					},
				}
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['salesOrderDetails', activeSalesOrderId]);
			queryClient.invalidateQueries(['listSalespersonsSalesOrders', { selectedSalesperson }]);
		},
	});

	const handleStatusChange = newStatus => {
		if (newStatus === 'Signed' || newStatus === 'Approved') {
			setStatus(newStatus);
			setIsModalOpen(true);
		} else {
			mutation.mutate({ status: newStatus, id: salesOrder.id });
		}
	};

	const handleConfirm = () => {
		mutation.mutate({ status, id: salesOrder.id });
		setIsModalOpen(false);
	};

	if (!activeSalesOrderId) {
		return (
			<div className={`w-full lg:w-3/4 ${darkMode ? 'bg-slate-700' : 'bg-white'} rounded-lg shadow-lg p-6`}>
				<div className={`text-center ${darkMode ? 'text-gray-300' : 'text-gray-500'}`}>
					Select a sales order to view details
				</div>
			</div>
		);
	}

	if (isLoading) {
		return (
			<div className={`w-full lg:w-3/4 ${darkMode ? 'bg-slate-700' : 'bg-white'} rounded-lg shadow-lg p-6`}>
				<div className="flex justify-center">
					<LogoLoading />
				</div>
			</div>
		);
	}

	if (error) {
		return (
			<div className={`w-full lg:w-3/4 ${darkMode ? 'bg-slate-700' : 'bg-white'} rounded-lg shadow-lg p-6`}>
				<div className="text-center text-red-400">Error loading sales order details</div>
			</div>
		);
	}

	if (!salesOrder) {
		return (
			<div className={`w-full lg:w-3/4 ${darkMode ? 'bg-slate-700' : 'bg-white'} rounded-lg shadow-lg p-6`}>
				<div className={`text-center ${darkMode ? 'text-gray-300' : 'text-gray-500'}`}>
					No details available
				</div>
			</div>
		);
	}

	const statusOptions = ['Draft', 'Pending', 'Signed', 'Approved', 'Rejected'];

	return (
		<div
			className={`w-full lg:w-3/4 ${darkMode ? 'bg-slate-700' : 'bg-white'} rounded-lg shadow-lg overflow-hidden h-full flex flex-col`}
		>
			<div className={`p-4 border-b ${darkMode ? 'border-slate-600' : 'border-gray-200'}`}>
				<div className="flex justify-between items-start">
					<div>
						<h2 className={`text-xl font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
							{salesOrder.companyName || 'Unknown Company'}
						</h2>
						<div className="mt-2 space-y-1">
							<p className={`text-sm ${darkMode ? 'text-gray-300' : 'text-gray-500'}`}>
								Salesperson: {salesOrder.salesPersonName || 'Unknown'}
							</p>
							<p className={`text-sm ${darkMode ? 'text-gray-300' : 'text-gray-500'}`}>
								Status:
								<select
									className={`ml-2 rounded border px-2 py-1 text-sm ${
										darkMode
											? 'border-slate-600 bg-slate-800 text-gray-300'
											: 'border-gray-300 bg-white text-gray-700'
									}`}
									value={salesOrder.status || ''}
									onChange={e => handleStatusChange(e.target.value)}
								>
									{statusOptions.map(option => (
										<option
											key={option}
											value={option}
											className={darkMode ? 'bg-slate-800' : 'bg-white'}
										>
											{option}
										</option>
									))}
								</select>
							</p>
						</div>
					</div>
					<div className="flex gap-2">
						{salesOrder.status !== 'Signed' && salesOrder.status !== 'Approved' && (
							<NavLink
								to="/sales-orders"
								state={{ activeSalesOrderId }}
								className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
							>
								Edit Sales Order
							</NavLink>
						)}
						<button
							disabled={!salesOrder.chargebeeSubscriptionId}
							onClick={() =>
								salesOrder.chargebeeSubscriptionId &&
								window.open(
									`https://trustedtechteam.chargebee.com/d/subscriptions/${salesOrder.chargebeeSubscriptionId}`,
									'_blank'
								)
							}
							className={`px-4 py-2 rounded transition-colors ${
								salesOrder.chargebeeSubscriptionId
									? 'bg-blue-500 text-white hover:bg-blue-600 cursor-pointer'
									: 'bg-gray-300 text-gray-500 cursor-not-allowed'
							}`}
						>
							View in ChargeBee
						</button>
						<button
							disabled={!salesOrder.salesForceAccountId}
							onClick={() =>
								salesOrder.salesForceAccountId &&
								window.open(
									`https://trustedtechteam.lightning.force.com/lightning/r/Account/${salesOrder.salesForceAccountId}/view`,
									'_blank'
								)
							}
							className={`px-4 py-2 rounded transition-colors ${
								salesOrder.salesForceAccountId
									? 'bg-blue-500 text-white hover:bg-blue-600 cursor-pointer'
									: 'bg-gray-300 text-gray-500 cursor-not-allowed'
							}`}
						>
							View Account
						</button>
					</div>
				</div>
			</div>

			<div className="flex-1 flex flex-col min-h-0">
				<div className="p-6">
					<div className="flex justify-between items-center">
						<div className="text-center">
							<h3 className={`text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
								Contract Value
							</h3>
							<p className={`mt-1 text-lg font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
								${formatMoney(salesOrder.totalContractValue)}
							</p>
						</div>
						<div className="text-center">
							<h3 className={`text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
								Monthly Revenue
							</h3>
							<p className={`mt-1 text-lg font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
								${formatMoney(salesOrder.totalAmount)}
							</p>
						</div>
						<div className="text-center">
							<h3 className={`text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
								Monthly Profit
							</h3>
							<p className={`mt-1 text-lg font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
								${formatMoney(salesOrder.monthlyProfit)}
							</p>
						</div>
						<div className="text-center">
							<h3 className={`text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
								Commission
							</h3>
							<p className={`mt-1 text-lg font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
								${formatMoney(salesOrder.commission)}
							</p>
						</div>
					</div>
				</div>

				<div className="flex-1 overflow-hidden flex flex-col min-h-0">
					<div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-slate-500 scrollbar-track-transparent">
						<table className={`min-w-full divide-y ${darkMode ? 'divide-slate-600' : 'divide-gray-200'}`}>
							<thead className={`${darkMode ? 'bg-slate-800' : 'bg-gray-50'} sticky top-0`}>
								<tr>
									<th
										className={`px-6 py-3 text-left text-xs font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'} uppercase tracking-wider`}
									>
										Product
									</th>
									<th
										className={`px-6 py-3 text-right text-xs font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'} uppercase tracking-wider`}
									>
										Price
									</th>
									<th
										className={`px-6 py-3 text-right text-xs font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'} uppercase tracking-wider`}
									>
										Cost
									</th>
									<th
										className={`px-6 py-3 text-right text-xs font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'} uppercase tracking-wider`}
									>
										Quantity
									</th>
									<th
										className={`px-6 py-3 text-right text-xs font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'} uppercase tracking-wider`}
									>
										Profit
									</th>
								</tr>
							</thead>
							<tbody className={`divide-y ${darkMode ? 'divide-slate-600' : 'divide-gray-200'}`}>
								{salesOrder.pricingSummary.map((item, index) => (
									<tr key={index} className={darkMode ? 'hover:bg-slate-600' : 'hover:bg-gray-50'}>
										<td
											className={`px-6 py-4 whitespace-nowrap text-sm ${darkMode ? 'text-white' : 'text-gray-900'}`}
										>
											{item.productName}
										</td>
										<td
											className={`px-6 py-4 whitespace-nowrap text-sm ${darkMode ? 'text-white' : 'text-gray-900'} text-right`}
										>
											${formatMoney(item.unitPrice)}
										</td>
										<td
											className={`px-6 py-4 whitespace-nowrap text-sm ${darkMode ? 'text-white' : 'text-gray-900'} text-right`}
										>
											${formatMoney(item.matchedUnitPrice)}
										</td>
										<td
											className={`px-6 py-4 whitespace-nowrap text-sm ${darkMode ? 'text-white' : 'text-gray-900'} text-right`}
										>
											{item.quantity}
										</td>
										<td
											className={`px-6 py-4 whitespace-nowrap text-sm text-right ${
												item.unitPrice - item.matchedUnitPrice < 0
													? 'text-red-400'
													: darkMode
														? 'text-white'
														: 'text-gray-900'
											}`}
										>
											${formatMoney((item.unitPrice - item.matchedUnitPrice) * item.quantity)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>

				<div
					className={`p-4 border-t ${darkMode ? 'border-slate-600 bg-slate-800' : 'border-gray-200 bg-gray-50'}`}
				>
					<div className={`text-sm ${darkMode ? 'text-gray-300' : 'text-gray-500'}`}>
						<p>ChargeBee ID: {salesOrder.chargebeeSubscriptionId || 'Not Available'}</p>
						<p className="mt-1">Sales Order ID: {salesOrder.salesOrderId || 'Not Available'}</p>
					</div>
				</div>
			</div>

			<ConfirmModal
				isOpen={isModalOpen}
				message="Setting a Sales Order's status to Signed / Approved will prevent further edits and cannot be undone!"
				onClose={() => setIsModalOpen(false)}
				onConfirm={handleConfirm}
			/>
		</div>
	);
}

export default CommissionDetails;
