import { useDarkMode } from '../../context/DarkModeContext';

function CommissionsList({
	activeSalesOrderId,
	salesOrders,
	debouncedSearchInput,
	selectedMonth,
	selectedStatus,
	selectedSalesperson,
	handleActiveSalesOrderChange,
}) {
	const { darkMode } = useDarkMode();

	const formatDate = dateString => {
		if (!dateString) return '';
		if (dateString.length <= 10) {
			const date = new Date(dateString);
			const options = {
				timeZone: 'America/Los_Angeles',
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
			};
			return date.toLocaleString('en-US', options);
		} else {
			const date = new Date(dateString); // An ISO string
			const options = {
				timeZone: 'America/Los_Angeles',
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
			};
			return date.toLocaleString('en-US', options);
		}
	};

	const pstDate = epoch => {
		const date = new Date(epoch * 1000); // Convert seconds to milliseconds

		// Convert to PST
		const options = {
			timeZone: 'America/Los_Angeles',
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		};

		return date.toLocaleString('en-US', options);
	};

	const searchFilter = salesOrder => {
		const searchTerm = debouncedSearchInput.trim().toLowerCase();
		if (searchTerm.length === 0) return true;
		if (searchTerm.length === 17 || searchTerm.length === 16) {
			return salesOrder.chargebeeSubscriptionId.toLowerCase().includes(searchTerm);
		}
		if (searchTerm.length === 36) {
			return salesOrder.id.includes(searchTerm);
		}
		if (searchTerm.length > 2) {
			return salesOrder.companyName.toLowerCase().includes(searchTerm);
		}
		return true;
	};

	const dateFilter = salesOrder => {
		if (selectedMonth === 'All Time') return true;
		const date = new Date(salesOrder.renewal_date);
		const month = date.getMonth() + 1;
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;

		if (selectedMonth === 'This Month') {
			return month === currentMonth && date.getFullYear() === currentDate.getFullYear();
		}
		if (selectedMonth === 'Last Month') {
			const lastMonth = currentMonth === 1 ? 12 : currentMonth - 1;
			const lastMonthYear = currentMonth === 1 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
			return month === lastMonth && date.getFullYear() === lastMonthYear;
		}
		return true;
	};

	const statusFilter = salesOrder => {
		if (selectedStatus === 'All Statuses') return true;
		if (!salesOrder.status) return false;
		if (
			(selectedStatus === 'Signed' || selectedStatus === 'Approved') &&
			(salesOrder.status === 'Signed' || salesOrder.status === 'Approved')
		) {
			return true;
		}
		return salesOrder.status === selectedStatus;
	};

	const filteredOrders =
		salesOrders
			?.filter(searchFilter)
			?.filter(dateFilter)
			?.filter(statusFilter)
			?.sort((a, b) => new Date(b._ts) - new Date(a._ts)) || [];

	return (
		<div
			className={`w-full lg:w-1/4 ${darkMode ? 'bg-slate-700' : 'bg-white'} rounded-lg shadow-lg overflow-auto flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-slate-500 scrollbar-track-transparent`}
		>
			<div
				className={`p-4 border-b ${darkMode ? 'border-slate-700 bg-slate-600' : 'border-gray-200 bg-gray-50'}`}
			>
				<h2 className={`text-xl font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>Sales Orders</h2>
			</div>
			<div className="overflow-auto scrollbar-thin scrollbar-thumb-slate-500 scrollbar-track-transparent">
				{filteredOrders.length === 0 ? (
					<div className={`p-4 text-center ${darkMode ? 'text-gray-300' : 'text-gray-500'}`}>
						No sales orders found for {selectedSalesperson}
					</div>
				) : (
					<div className={`divide-y ${darkMode ? 'divide-slate-600' : 'divide-gray-200'}`}>
						{filteredOrders.map((order, index) => (
							<div
								key={`${order.id}-${index}`}
								onClick={() => handleActiveSalesOrderChange(order.id)}
								className={`cursor-pointer p-4 transition-colors ${
									activeSalesOrderId === order.id
										? darkMode
											? 'bg-slate-800'
											: 'bg-blue-50'
										: darkMode
											? 'hover:bg-slate-600'
											: 'hover:bg-gray-50'
								}`}
							>
								<div className="flex flex-col gap-1">
									<div className={`font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
										{order.companyName || 'Unknown Company'}
									</div>
									<div className={darkMode ? 'text-gray-300' : 'text-gray-500'}>
										Updated: {pstDate(order._ts)}
									</div>
									<div className={darkMode ? 'text-gray-300' : 'text-gray-500'}>
										Created: {formatDate(order.createdDate)}
									</div>
									<div className={darkMode ? 'text-gray-300' : 'text-gray-500'}>
										{order.chargebeeSubscriptionId}
									</div>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
}

export default CommissionsList;
