import logoDark from '../../assets/ttt-logo.png';
import logoLight from '../../assets/ttt-logo_light.png';
import HomeIcon from '@mui/icons-material/Home';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CalculateIcon from '@mui/icons-material/Calculate';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ArticleIcon from '@mui/icons-material/Article';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import SettingsIcon from '@mui/icons-material/Settings';
import { PiSidebar } from 'react-icons/pi';
import { useDarkMode } from '../../context/DarkModeContext';
import { useState } from 'react';
import { ArrowBack } from '@mui/icons-material';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import FeedbackButton from '../common/FeedbackButton';
import Loading from '../common/Loading';
import { environment } from '../../authConfig';
import { NavLink } from 'react-router-dom';
import { useAuth, permissionMatrixAppRoles } from '../../context/AuthContext';

function LeftSidebar() {
	const { darkMode } = useDarkMode();
	const { user } = useAuth();
	const [sidebarExpanded, setSidebarExpanded] = useState(true);

	const iconMap = {
		Home: <HomeIcon />,
		Commissions: <RequestQuoteIcon />,
		'My Documents': <FolderCopyIcon />,
		'Consumption Report': <AssessmentIcon />,
		'Sales Order Renewal': <ArticleIcon />,
		'Sales Order Acquisition': <ReceiptIcon />,
		'Platinum Calculator': <CalculateIcon />,
		Analytics: <AnalyticsIcon />,
		'Deal Desk': <ScreenshotMonitorIcon />,
		Settings: <SettingsIcon />,
	};

	const toggleSidebarView = () => {
		setSidebarExpanded(prevState => !prevState);
	};

	const version = import.meta.env.VITE_VERSION;

	const routeMap = {
		Home: 'home',
		Commissions: 'commissions',
		'My Documents': 'documents',
		'Consumption Report': 'consumption-reports',
		'Sales Order Renewal': 'sales-orders',
		'Sales Order Acquisition': 'sales-orders-nce',
		'Platinum Calculator': 'platinum-calculator',
		Analytics: 'analytics',
		'Deal Desk': 'dealdesk',
		Settings: 'settings',
	};

	const rolesPriority = [
		'admin',
		'operations',
		'finmanager',
		'finance',
		'leadership',
		'aemanager',
		'ammanager',
		'manager',
		'am',
		'ae',
		'platcalc',
		'bdr',
	];
	// .find() linearly indexes [0..], so we can determine order of permissions
	const highestPermissionRole = user?.userRoles?.find(role => rolesPriority.includes(role)) || 'unknown';

	return (
		<div
			className={`z-40 flex-col justify-between h-full overflow-y-auto border-gray-200 scrollbar-hide border-r-1 text-slate-900 md:flex dark:border-gray-900 dark:text-white ${sidebarExpanded ? 'xl:min-w-[300px] w-64' : 'xl:min-w-[70px]'}`}
		>
			<div className="flex flex-col">
				<div
					className={`max-h-[22rem] rounded-2xl pb-2 ${sidebarExpanded ? 'flex' : 'flex flex-col items-center gap-5'} p-7 justify-between`}
				>
					<div className="flex items-start gap-2">
						<img className="w-[28px]" src={darkMode ? logoDark : logoLight} alt="TTT Logo" />
						{sidebarExpanded && (
							<div className="flex flex-col mt-1">
								<h1 className="flex items-center font-bold text-md text-zinc-800 dark:text-slate-300">
									Sales Hub
									<span className="ms-2 text-xs font-bold rounded-md bg-gray-300 px-2 py-1 text-zinc-100 bg-gradient-to-r from-blue-400 via-green-400 to-blue-400 bg-[length:200%_200%] animate-moveGradient">
										Beta
									</span>
								</h1>
								<span className="text-sm text-zinc-800 dark:text-slate-300">v{version}</span>
							</div>
						)}
					</div>
				</div>
				<div className="xl:min-w[300px] hidden max-h-[22rem] rounded-2xl pb-2 md:inline">
					<div className="flex flex-col items-start p-4 space-y-3 text-sm">
						{user?.userRoles ? (
							permissionMatrixAppRoles[highestPermissionRole]?.map((pageName, index) => {
								return (
									pageName != 'Settings' && (
										<NavLink
											key={`${index}-${pageName}`}
											className={({ isActive }) =>
												`flex w-full items-center ${sidebarExpanded ? '' : 'justify-center'} gap-2 rounded-lg p-3 text-start 
                                                ${isActive ? 'bg-blue-500 text-white' : 'hover:bg-black/5'}`
											}
											to={`/${routeMap[pageName]}`}
										>
											{iconMap[pageName]}
											{sidebarExpanded && pageName}
										</NavLink>
									)
								);
							})
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
			<div className="flex flex-col items-start p-4 space-y-3 text-sm">
				{environment === 'production' || environment === 'devtest' ? (
					<FeedbackButton sidebarExpanded={sidebarExpanded} />
				) : (
					<></>
				)}
				{(user?.userRoles && user?.userRoles.includes('operations')) ||
				user?.userRoles.includes('finance') ||
				user?.userRoles.includes('finmanager') ? (
					<NavLink
						key="Settings"
						className={({ isActive }) =>
							`flex w-full items-center ${sidebarExpanded ? '' : 'justify-center'} gap-2 rounded-lg p-3 text-start 
                                        ${isActive ? 'bg-blue-500 text-white' : 'hover:bg-black/5'}`
						}
						to={`/${routeMap['Settings']}`}
					>
						{iconMap['Settings']}
						{sidebarExpanded && 'Settings'}
					</NavLink>
				) : (
					<></>
				)}
				<button
					className={`flex w-full items-center ${sidebarExpanded ? '' : 'justify-center'} gap-2 rounded-lg p-3 text-start text-neutral-800 hover:text-gray-600 dark:text-slate-400 dark:hover:text-slate-300`}
					onClick={toggleSidebarView}
				>
					{sidebarExpanded ? (
						<>
							<ArrowBack />
							Collapse Menu
						</>
					) : (
						<Tippy
							content="Expand sidebar"
							placement="right"
							animation="fade"
							theme={darkMode ? 'dark' : 'light'}
							delay={500}
						>
							<div>
								<PiSidebar />
							</div>
						</Tippy>
					)}
				</button>
			</div>
		</div>
	);
}

export default LeftSidebar;
