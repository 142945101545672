/* eslint-disable no-unused-vars */
import { Page, Text, View, Document, StyleSheet, Image, pdf, Font, Link } from '@react-pdf/renderer';
import logoPath from '../../assets/ttt-horizontal-lockup_primary.png';
import logoPath2 from '../../assets/microsoft-solutions-partner_no-background.jpg';
import calibri from '../../assets/calibri.ttf';
import calibrib from '../../assets/calibrib.ttf';
import calibrii from '../../assets/calibrii.ttf';
import calibriz from '../../assets/calibriz.ttf';

Font.register({
	family: 'Calibri',
	fonts: [
		{ src: calibri },
		{ src: calibrib, fontWeight: 'bold' },
		{ src: calibrii, fontStyle: 'italic' },
		{ src: calibriz, fontWeight: 'bold', fontStyle: 'italic' },
	],
});

const currencySymbols = {
	USD: '$',
	CAD: '$',
	GBP: '£',
	EUR: '€',
	CHF: 'Chf.',
	DKK: 'kr',
	NOK: 'kr',
	SEK: 'kr',
};

const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		padding: 30,
		fontFamily: 'Calibri',
	},
	section: {
		marginBottom: 10,
	},
	heading: {
		fontSize: 12,
		marginBottom: 10,
		fontWeight: 'bold',
	},
	boldText: {
		fontWeight: 'bold',
	},
	table: {
		display: 'table',
		width: 'auto',
		marginTop: 20,
	},
	tableRow: {
		flexDirection: 'row',
	},
	tableRowHeader: {
		flexDirection: 'row',
		backgroundColor: '#f0f0f0',
	},
	tableRowEven: {
		flexDirection: 'row',
		backgroundColor: '#f9f9f9',
	},
	tableRowOdd: {
		flexDirection: 'row',
		backgroundColor: '#ffffff',
	},
	tableCol: {
		padding: 8,
	},
	tableCell: {
		fontSize: 10,
		textAlign: 'center',
	},
	tableCellProduct: {
		margin: 'auto',
		marginTop: 5,
		fontSize: 10,
	},
	tableHeaderRed: {
		backgroundColor: '#dc143c',
		color: 'black',
		fontWeight: 'bold',
	},
	tableHeaderGreen: {
		backgroundColor: '#60C659',
		color: 'black',
		fontWeight: 'bold',
	},
	tableCellRed: {
		backgroundColor: '#FFCCCC',
		padding: 8,
	},
	tableCellGreen: {
		backgroundColor: '#E0FFEB',
		padding: 8,
	},
	clientSenderInfo: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: 20,
	},
	clientInfo: {
		width: '50%',
		fontSize: 10,
	},
	senderInfo: {
		width: '50%',
		fontSize: 10,
		textAlign: 'right',
	},
	totalSection: {
		marginTop: 20,
		fontSize: 13,
		fontStyle: 'italic',
		textAlign: 'right',
		fontWeight: 'bold',
	},
	productCol: {
		width: '33%',
	},
	smallCol: {
		width: '15%',
	},
	summaryImage: {
		width: 170,
		height: 'auto',
		marginTop: 20,
	},
	footer: {
		position: 'absolute',
		bottom: 30,
		left: 30,
		right: 30,
		textAlign: 'center',
		fontSize: 9,
		color: 'gray',
	},
	link: {
		color: 'blue',
		textDecoration: 'underline',
	},
});

const ConsumptionReportPDF = ({
	rows,
	clientName,
	clientCompany,
	clientEmail,
	clientPhone,
	selectedSender,
	senderEmail,
	senderPhone,
	totalCurrentVendor,
	totalTtt,
	totalSavings,
	totalAnnualSavings,
	currency,
	currentDate,
	currentVendor,
	referenceCode,
}) => (
	<Document>
		<Page style={styles.page} size="A4" orientation="landscape">
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Image src={logoPath} style={{ width: 150, height: 'auto' }} />
				<View style={{ textAlign: 'right' }}>
					<Text style={[styles.heading, styles.boldText]}>
						{' '}
						Date: {currentDate.toLocaleDateString('en-US')}
					</Text>
					<Text style={[styles.heading, styles.boldText]}>Reference: {referenceCode}</Text>
				</View>
			</View>
			<View style={styles.clientSenderInfo}>
				<View style={styles.clientInfo}>
					<Text style={[styles.heading, styles.boldText]}>Prepared For:</Text>
					<Text>{clientName}</Text>
					<Text>{clientCompany}</Text>
					<Text>{clientEmail}</Text>
					<Text>{clientPhone}</Text>
				</View>
				<View style={styles.senderInfo}>
					<Text style={[styles.heading, styles.boldText]}>Prepared By:</Text>
					<Text>{selectedSender?.full_name}</Text>
					<Text>{senderEmail}</Text>
					<Text>{senderPhone}</Text>
				</View>
			</View>
			<View style={styles.section}>
				<View style={styles.table}>
					<View style={[styles.tableRow, styles.tableRowHeader]}>
						<View style={[styles.tableCol, styles.productCol]}>
							<Text style={[styles.tableCell, styles.boldText]}>Product</Text>
						</View>
						<View style={[styles.tableCol, styles.smallCol]}>
							<Text style={[styles.tableCell, styles.boldText]}>Assigned Seats</Text>
						</View>
						<View style={[styles.tableCol, styles.smallCol]}>
							<Text style={[styles.tableCell, styles.boldText]}>Total Seats</Text>
						</View>
						<View style={[styles.tableCol, styles.smallCol]}>
							<Text style={[styles.tableCell, styles.boldText]}>MSRP</Text>
						</View>
						<View style={[styles.tableCol, styles.smallCol, styles.tableHeaderRed]}>
							<Text style={[styles.tableCell, styles.boldText]}>
								{currentVendor} {currencySymbols[currency]}/Seat
							</Text>
						</View>
						<View style={[styles.tableCol, styles.smallCol, styles.tableHeaderRed]}>
							<Text style={[styles.tableCell, styles.boldText]}>
								{currentVendor} {currencySymbols[currency]} (Total)
							</Text>
						</View>
						<View style={[styles.tableCol, styles.smallCol, styles.tableHeaderGreen]}>
							<Text style={[styles.tableCell, styles.boldText]}>TTT/Seat</Text>
						</View>
						<View style={[styles.tableCol, styles.smallCol, styles.tableHeaderGreen]}>
							<Text style={[styles.tableCell, styles.boldText]}>TTT (Total)</Text>
						</View>
						<View style={[styles.tableCol, styles.smallCol]}>
							<Text style={[styles.tableCell, styles.boldText]}>Line Item Savings</Text>
						</View>
					</View>
					{rows.map((row, index) => (
						<View
							style={[styles.tableRow, index % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd]}
							key={row.id}
							wrap={false}
						>
							<View style={[styles.tableCol, styles.productCol]}>
								<Text style={styles.tableCell}>
									{row.product ? row.product.label : 'Select a product'}
								</Text>
							</View>
							<View style={[styles.tableCol, styles.smallCol]}>
								<Text style={styles.tableCell}>{row.assignedSeats}</Text>
							</View>
							<View style={[styles.tableCol, styles.smallCol]}>
								<Text style={styles.tableCell}>{row.totalSeats}</Text>
							</View>
							<View style={[styles.tableCol, styles.smallCol]}>
								<Text style={styles.tableCell}>
									{currencySymbols[currency]}
									{row.msrp.toFixed(2)}
								</Text>
							</View>
							<View style={[styles.tableCol, styles.smallCol, styles.tableCellRed]}>
								<Text style={styles.tableCell}>
									{currencySymbols[currency]}
									{row.currentVendorPrice.toFixed(2)}
								</Text>
							</View>
							<View style={[styles.tableCol, styles.smallCol, styles.tableCellRed]}>
								<Text style={styles.tableCell}>
									{currencySymbols[currency]}
									{(row.currentVendorPrice * row.totalSeats).toFixed(2)}
								</Text>
							</View>
							<View style={[styles.tableCol, styles.smallCol, styles.tableCellGreen]}>
								<Text style={styles.tableCell}>
									{currencySymbols[currency]}
									{row.tttPrice.toFixed(2)}
								</Text>
							</View>
							<View style={[styles.tableCol, styles.smallCol, styles.tableCellGreen]}>
								<Text style={styles.tableCell}>
									{currencySymbols[currency]}
									{(row.tttPrice * row.assignedSeats).toFixed(2)}
								</Text>
							</View>
							<View style={[styles.tableCol, styles.smallCol]}>
								<Text style={styles.tableCell}>
									{currencySymbols[currency]}
									{row.lineItemSavings.toFixed(2)}
								</Text>
							</View>
						</View>
					))}
				</View>
			</View>
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Image src={logoPath2} style={styles.summaryImage} />
				<View style={styles.totalSection}>
					<Text>
						{currentVendor} / Month: {currencySymbols[currency]}
						{totalCurrentVendor.toFixed(2)}
					</Text>
					<Text>
						Trusted Tech Team / Month: {currencySymbols[currency]}
						{totalTtt.toFixed(2)}
					</Text>
					<Text>
						Savings / Month: {currencySymbols[currency]}
						{totalSavings.toFixed(2)}
					</Text>
					<Text>
						Savings / Year: {currencySymbols[currency]}
						{totalAnnualSavings.toFixed(2)}
					</Text>
				</View>
			</View>
			<View style={styles.footer} wrap={false}>
				<Text>
					Visit us online:{' '}
					<Link src="https://trustedtechteam.com" style={styles.link}>
						trustedtechteam.com
					</Link>
				</Text>
				<Text>
					Learn more about our Microsoft Solutions Partner designations:{' '}
					<Link src="https://trustedtechteam.com/partner" style={styles.link}>
						trustedtech.team/partner
					</Link>
				</Text>
				<Text>
					Infrastructure • Data & AI • Digital & App Innovation • Business Applications • Modern Work •
					Security
				</Text>
			</View>
		</Page>
	</Document>
);

export default ConsumptionReportPDF;
