import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Forbidden() {
	return (
		<div className="flex flex-col h-full w-full justify-center items-center gap-4">
			<p> No so fast! You shouldn't be here!</p>
			<button className="px-4 py-2 bg-neutral-800 dark:bg-neutral-300 dark:text-black text-white rounded-lg">
				<NavLink to="/home">Return to Safety</NavLink>
			</button>
		</div>
	);
}
