/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useState } from 'react';
import axios from 'axios';
import useDebounce from '../hooks/useDebounce';
import { useQuery } from 'react-query';
import DocumentList from '../components/myDocuments/documentList';
import { serverAddress } from '../authConfig';
import { useAuth } from '../context/AuthContext';

function MyDocuments({}) {
	const { user, accessToken } = useAuth();

	const [selectedSalesperson, setSelectedSalesperson] = useState(user?.userName);
	const [selectedMonth, setSelectedMonth] = useState('All Time');
	const [searchInput, setSearchInput] = useState('');
	const [selectedStatus, setSelectedStatus] = useState('All Statuses');
	const [activeSalesOrderId, setActiveSalesOrderId] = useState('');
	const [activeSalesOrderNceId, setActiveSalesOrderNceId] = useState('');
	const [activeCReportId, setActiveCReportId] = useState('');
	const debouncedSearchInput = useDebounce(searchInput, 500);

	const [activePage, setActivePage] = useState({ tab: 'Sales Orders', page: 1 });

	const [salesOrders, setSalesOrders] = useState([]);
	const [salesOrdersContinuationToken, setSalesOrdersContinuationToken] = useState([null]);

	const [salesOrdersNce, setSalesOrdersNce] = useState([]);
	const [salesOrdersNceContinuationToken, setSalesOrdersNceContinuationToken] = useState([null]);

	const [consumptionReports, setConsumptionReports] = useState([]);
	const [consumptionReportsContinuationToken, setConsumptionReportsContinuationToken] = useState([null]);

	//fetch AEs and AMs
	const fetchSalespersons = async () => {
		// AMs/AEs should only be able to see their own sales orders if no elevated permissions
		if (!user?.isElevatedPermissions) return [{ full_name: user?.userName }];

		try {
			//fetch AMs
			const amResponse = await axios.get(`${serverAddress}/salespersons/account-managers`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': `${user?.userName}`,
				},
			});

			//fetch AEs
			const aeResponse = await axios.get(`${serverAddress}/salespersons/account-executives`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': `${user?.userName}`,
				},
			});

			if (amResponse.status === 200 && aeResponse.status === 200) {
				//combine both AMs and AEs
				const combinedSalespersons = [...amResponse.data.salespersons, ...aeResponse.data.salespersons];
				return combinedSalespersons;
			}
		} catch (error) {
			console.error(error);
		}
	};

	//fetch sales orders based on the selected Salesperson
	const fetchUsersSalesOrders = async (salesperson, continuationToken) => {
		if (salesperson === undefined) return;

		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
			'X-User-Name': `${salesperson}`,
		};
		if (continuationToken) {
			headers['cont-token'] = continuationToken;
		}

		try {
			const response = await axios.get(`${serverAddress}/sales-orders/renewal?salesperson=${salesperson}`, {
				headers: headers,
			});
			if (response.status === 200) {
				if (response.data.continuation_token !== null) {
					setSalesOrdersContinuationToken(response.data.continuation_token);
				}
				if (response.data.results) {
					setSalesOrders(prev => [...prev, ...response.data.results]);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	// console.info(salesorderContTokens);

	//fetch sales orders based on the selected Salesperson
	const fetchUsersSalesOrdersNce = async (salesperson, continuationToken) => {
		if (salesperson === undefined) return;

		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
			'X-User-Name': `${salesperson}`,
		};
		if (continuationToken) {
			headers['cont-token'] = continuationToken;
		}

		try {
			const response = await axios.get(`${serverAddress}/sales-orders/nce?salesperson=${salesperson}`, {
				headers: headers,
			});
			if (response.status === 200) {
				if (response.data.continuation_token !== null) {
					setSalesOrdersNceContinuationToken(response.data.continuation_token);
				}
				if (response.data.results) {
					setSalesOrdersNce(prev => [...prev, ...response.data.results]);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	//fetch consumption reports based on the selected Salesperson
	const fetchUsersConsumptionReports = async (salesperson, continuationToken) => {
		if (salesperson === undefined) return;

		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
			'X-User-Name': `${salesperson}`,
		};
		if (continuationToken) {
			headers['cont-token'] = continuationToken;
		}

		try {
			const response = await axios.get(`${serverAddress}/consumption-reports?salesperson=${salesperson}`, {
				headers: headers,
			});
			if (response.status === 200) {
				if (response.data.continuation_token !== null) {
					setConsumptionReportsContinuationToken(response.data.continuation_token);
				}
				if (response.data.results) {
					setConsumptionReports(prev => [...prev, ...response.data.results]);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	//useQuery to get both AMs and AEs
	const {
		data: salespersons,
		error: salespersonsError,
		isLoading: salespersonsIsLoading,
	} = useQuery({
		queryKey: ['salespersons'],
		queryFn: () => fetchSalespersons(),
		staleTime: 30 * 60 * 1000,
	});

	useEffect(() => {
		setSalesOrdersContinuationToken(null);
	}, [selectedSalesperson]);

	//useQuery to fetch sales orders for the selected Salesperson
	const { isSalesOrdersPending, isSalesOrdersError, salesOrdersError } = useQuery({
		queryKey: ['listSalespersonsSalesOrders', salesOrdersContinuationToken, selectedSalesperson],
		refetchOnWindowFocus: false,
		queryFn: () => {
			fetchUsersSalesOrders(selectedSalesperson, salesOrdersContinuationToken);
		},
		staleTime: 2 * 60 * 1000, // Cache data for 2 mins. User can clear cache by refreshing
		placeholderData: previousData => previousData,
		enabled: !!salespersons,
	});

	useEffect(() => {
		setSalesOrdersNceContinuationToken(null);
	}, [selectedSalesperson]);

	//useQuery to fetch sales orders NCE for the selected Salesperson
	const { isSalesOrdersNcePending, isSalesOrdersNceError, salesOrdersNceError } = useQuery({
		queryKey: ['listSalespersonsSalesOrdersNce', salesOrdersNceContinuationToken, selectedSalesperson],
		refetchOnWindowFocus: false,
		queryFn: () => {
			fetchUsersSalesOrdersNce(selectedSalesperson, salesOrdersNceContinuationToken);
		},
		staleTime: 2 * 60 * 1000, // Cache data for 2 mins. User can clear cache by refreshing
		placeholderData: previousData => previousData,
		enabled: !!salespersons,
	});

	useEffect(() => {
		setConsumptionReportsContinuationToken(null);
	}, [selectedSalesperson]);

	//useQuery to fetch consumption reports for the selected Salesperson
	const { isConsumptionReportPending, isConsumptionReportError, consumptionReportError } = useQuery({
		queryKey: ['listSalespersonsConsumptionReports', consumptionReportsContinuationToken, selectedSalesperson],
		refetchOnWindowFocus: false,
		queryFn: () => {
			fetchUsersConsumptionReports(selectedSalesperson, consumptionReportsContinuationToken);
		},
		staleTime: 2 * 60 * 1000, // Cache data for 2 mins. User can clear cache by refreshing
		placeholderData: previousData => previousData,
		enabled: !!salespersons,
	});

	const tabMap = {
		'Sales Orders': salesOrdersContinuationToken,
		'Consumption Reports': salesOrdersNceContinuationToken,
		'Sales Orders NCE': consumptionReportsContinuationToken,
	};

	return (
		<div>
			<DocumentList
				activeSalesOrderId={activeSalesOrderId}
				activeSalesOrderNceId={activeSalesOrderNceId}
				activeCReportId={activeCReportId}
				consumptionReports={consumptionReports}
				salesOrders={salesOrders}
				salesOrdersNce={salesOrdersNce}
				debouncedSearchInput={debouncedSearchInput}
				selectedMonth={selectedMonth}
				selectedStatus={selectedStatus}
				selectedSalesperson={selectedSalesperson}
				handleActiveSalesOrderChange={salesOrderId => {
					setActiveSalesOrderId(salesOrderId);
				}}
				handleActiveSalesOrderNceChange={salesOrderNceId => {
					setActiveSalesOrderNceId(salesOrderNceId);
				}}
				handleActiveConsumptionReportChange={cReportId => {
					setActiveCReportId(cReportId);
				}}
				serverAddress={serverAddress}
				accessToken={accessToken}
				userName={user?.userName}
				activePage={activePage}
				handleNextPage={activeTab => {
					setActivePage(prev => ({
						tab: activeTab,
						page: prev.page + 1,
					}));
				}}
				handlePrevPage={activeTab => {
					setActivePage(prev => ({
						tab: activeTab,
						page: prev.page - 1,
					}));
				}}
			/>
		</div>
	);
}

export default MyDocuments;
