/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import { serverAddress } from '../authConfig';
import { pdf, Font } from '@react-pdf/renderer';
import 'react-datepicker/dist/react-datepicker.css';
import {
	ImDownload,
	ImSearch,
	ImPlus,
	ImBin,
	ImPencil,
	ImLock,
	ImShocked,
	ImCheckboxChecked,
	ImSpinner4,
} from 'react-icons/im';
import { SalesOrderPDFBasic } from './SO_NCE_template_basic';
import { formatMoney, uploadSalesOrderNCEtoCosmos } from '../utils';
import { useQueryClient } from 'react-query';

const currencyOptions = [
	{ value: 'USD', label: '🇺🇸 USD' },
	{ value: 'CAD', label: '🇨🇦 CAD' },
	{ value: 'GBP', label: '🇬🇧 GBP' },
	{ value: 'EUR', label: '🇪🇺 EUR' },
	{ value: 'CHF', label: '🇨🇭 CHF' },
	{ value: 'DKK', label: '🇩🇰 DKK' },
	{ value: 'NOK', label: '🇳🇴 NOK' },
	{ value: 'SEK', label: '🇸🇪 SEK' },
];

const currencySymbols = {
	USD: '$',
	CAD: '$',
	GBP: '£',
	EUR: '€',
	CHF: 'Chf.',
	DKK: 'kr',
	NOK: 'kr',
	SEK: 'kr',
};

const netTermsOptions = [
	{ value: 'NONE', label: 'NONE' },
	{ value: 'Net 7', label: 'Net 7' },
	{ value: 'Net 15', label: 'Net 15' },
	{ value: 'Net 30', label: 'Net 30' },
	{ value: 'Net 45', label: 'Net 45' },
	{ value: 'Net 60', label: 'Net 60' },
	{ value: 'Net 90', label: 'Net 90' },
];

const billingFrequencyOptions = [
	{ value: 'NONE', label: 'NONE' },
	{ value: 'Monthly', label: 'Monthly' },
	{ value: 'Annually', label: 'Annually' },
];

const platSupportOptions = [
	{ value: 'NONE', label: 'NONE' },
	{ value: 'Basic', label: 'Basic' },
	{ value: 'Standard', label: 'Standard' },
	{ value: 'Premium', label: 'Premium' },
	{ value: 'Elite', label: 'Elite' },
];

const SOrderNCE = () => {
	const { user, accessToken } = useAuth();
	const [view, setView] = useState('info');
	const [term, setTerm] = useState('');
	const [offerValidThrough, setOfferValidThrough] = useState(new Date());
	const [defaultServiceStartDate, setDefaultServiceStartDate] = useState(new Date());
	const [defaultServiceEndDate, setDefaultServiceEndDate] = useState(new Date());
	const [netTerms, setNetTerms] = useState('');
	const [billingFrequency, setBillingFrequency] = useState('');
	const [options, setOptions] = useState();
	const [platinumSupport, setPlatinumSupport] = useState('NONE');
	const [companyAddress, setCompanyAddress] = useState('');
	const [companyCity, setCompanyCity] = useState('');
	const [companyState, setCompanyState] = useState('');
	const [companyZip, setCompanyZip] = useState('');
	const [companyCountry, setCompanyCountry] = useState('');
	const [rows, setRows] = useState([]);
	const [companyName, setCompanyName] = useState('');
	const [senderName, setSenderName] = useState('');
	const [quoteNumber, setQuoteNumber] = useState('');
	const [products, setProducts] = useState([]);
	const [currency, setCurrency] = useState('USD');
	const location = useLocation();
	const { activeCReportId } = location.state || {};
	const { activeSalesOrderNceId } = location.state || {};
	const [status, setStatus] = useState('Draft');

	const queryClient = useQueryClient();

	const fetchSalesOrderNCEDetails = async (activeSalesOrderNceId, accessToken, userName, serverAddress) => {
		try {
			const response = await axios.get(`${serverAddress}/sales-orders/nce/${activeSalesOrderNceId}`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': `${userName}`,
				},
			});

			if (response.status === 200) {
				const salesOrderNceDetails = response.data;

				setCompanyName(salesOrderNceDetails.companyName || '');
				setSenderName(salesOrderNceDetails.salesPersonName || '');
				setQuoteNumber(salesOrderNceDetails.salesOrderId || ''); //FIX: ADD REF CODE IN JSON SUMMARY
				setTerm(salesOrderNceDetails.termMonths || '');
				setOfferValidThrough(new Date(salesOrderNceDetails.validThrough || Date.now()));
				setDefaultServiceStartDate(new Date(salesOrderNceDetails.servicesStartDate || Date.now()));
				setDefaultServiceEndDate(new Date(salesOrderNceDetails.servicesEndDate || Date.now()));
				setNetTerms(salesOrderNceDetails.netTerms || '');
				setBillingFrequency(salesOrderNceDetails.billingFrequency || '');
				setPlatinumSupport(salesOrderNceDetails.platinumSupport || 'NONE');
				setCompanyAddress(salesOrderNceDetails.companyAddress || '');
				setCompanyCity(salesOrderNceDetails.companyCity || '');
				setCompanyState(salesOrderNceDetails.companyState || '');
				setCompanyZip(salesOrderNceDetails.companyZIP || '');
				setCompanyCountry(salesOrderNceDetails.companyCountry || '');
				setCurrency(salesOrderNceDetails.currency || 'USD');
				setStatus(salesOrderNceDetails.status || 'Draft');

				const mappedRows = salesOrderNceDetails.pricingSummary.map(item => ({
					id: item.addonID || uuidv4(),
					product: item.productName || 'Unknown Product',
					assignedSeats: item.quantity || 0,
					msrp: item.unitPrice || 0, //is actually erpPrice, just named unitPrice in json schema
					totalSeats: (item.unitPrice || 0) * (item.quantity || 0),
					effectiveStartDate: item.effectiveStartDate ? new Date(item.effectiveStartDate) : new Date(),
				}));
				setRows(mappedRows);
			}
		} catch (error) {
			console.error('Error fetching Sales Order (NCE) details:', error);
		}
	};

	useEffect(() => {
		location.state = null; // Wipe state so editing is cancelled by refreshing page
		if (activeSalesOrderNceId) {
			fetchSalesOrderNCEDetails(activeSalesOrderNceId, accessToken, user?.userName, serverAddress);
		}
		location.state = null; // Wipe state so editing is cancelled by refreshing page
	}, [activeSalesOrderNceId]);

	const fetchConsumptionReportDetails = async reportId => {
		try {
			console.log(`${serverAddress}/consumption-reports/${reportId}`);

			const response = await axios.get(`${serverAddress}/consumption-reports/${reportId}`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': user?.userName,
				},
			});

			if (response.status === 200) {
				const reportDetails = response.data;
				console.log('Consumption Report Details:', reportDetails);

				setCompanyName(reportDetails.clientCompany || '');
				setSenderName(reportDetails.selectedSender?.full_name || '');
				setQuoteNumber(reportDetails.referenceCode || '');
				setCurrency(reportDetails.currency || '');

				const mappedRows = reportDetails.rows.map(row => ({
					id: row.id || uuidv4(),
					product: row.product?.label || 'Unknown Product',
					assignedSeats: row.assignedSeats || 0,
					msrp: row.tttPrice || 0,
					totalSeats: row.tttTotal || 0,
					effectiveStartDate: row.effectiveStartDate ? new Date(row.effectiveStartDate) : new Date(),
				}));

				setRows(mappedRows);
			}
		} catch (error) {
			console.error('Error fetching consumption report details:', error);
		}
	};

	useEffect(() => {
		if (activeCReportId) {
			fetchConsumptionReportDetails(activeCReportId); //fetch report details
		}
	}, [activeCReportId]);

	const handleDeleteRow = index => {
		const updatedRows = rows.filter((row, rowIndex) => rowIndex !== index);
		setRows(updatedRows);
	};

	useEffect(() => {
		if (!currency) return;

		const fetchProducts = async () => {
			try {
				console.log(`Fetching products for currency: ${currency}`);

				const response = await axios.get(`${serverAddress}/products?pk_filter=${currency}`, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
						'X-User-Name': `${user?.userName}`,
					},
				});

				const matchList = response.data;
				console.log('Match List Data:', matchList);

				const filteredProducts = matchList
					.map(item => {
						const variantP1Y = item.pk_msrp_variants?.find(
							variant => variant.pk_msrp.includes('P1Y|monthly') && variant.pk_msrp.endsWith(currency)
						);
						const variantP1M = item.pk_msrp_variants?.find(
							variant => variant.pk_msrp.includes('P1M|monthly') && variant.pk_msrp.endsWith(currency)
						);

						return {
							value: item.sku_title,
							label: item.sku_title,
							msrpP1Y: variantP1Y?.erp_price || 0,
							pk_msrpP1Y: variantP1Y?.pk_msrp || 'N/A',
							unit_priceP1Y: variantP1Y?.unit_price || 0,
							unit_price_in_usdP1Y: variantP1Y?.unit_price_in_usd || 0,
							msrpP1M: variantP1M?.erp_price || 0,
							pk_msrpP1M: variantP1M?.pk_msrp || 'N/A',
							unit_priceP1M: variantP1M?.unit_price || 0,
							unit_price_in_usdP1M: variantP1M?.unit_price_in_usd || 0,
						};
					})
					.sort((a, b) => a.label.localeCompare(b.label));

				console.log('Filtered Products: ', filteredProducts);

				setProducts(filteredProducts);
				setOptions(filteredProducts);

				setRows(prevRows => {
					const updatedRows = prevRows.map(row => {
						const selectedProduct = filteredProducts.find(product => product.label === row.product);

						if (selectedProduct) {
							// Initialize with P1Y as the default pricing
							return {
								...row,
								msrp: selectedProduct.msrpP1Y,
								totalSeats: selectedProduct.msrpP1Y * row.assignedSeats,
								matchedPkID: selectedProduct.pk_msrpP1Y || 'N/A',
								matchedUnitPrice: selectedProduct.unit_priceP1Y || 0,
								matchedUnitPriceInUsd: selectedProduct.unit_price_in_usdP1Y || 0,
								isMonthlyP1M: false, // Initialize with P1Y (annual) pricing as default
							};
						}

						return row;
					});

					console.log('Updated Rows before setting state:', updatedRows);
					return updatedRows;
				});
			} catch (error) {
				console.error('Error fetching products:', error);
			}
		};

		fetchProducts();
	}, [currency, serverAddress, accessToken, user]);

	const handleP1MCheckboxToggle = (index, isChecked) => {
		setRows(prevRows => {
			const updatedRows = [...prevRows];
			const selectedRow = updatedRows[index];
			const selectedProduct = products.find(product => product.label === selectedRow.product);

			if (selectedProduct) {
				// Toggle between P1M and P1Y pricing based on checkbox state
				updatedRows[index] = {
					...selectedRow,
					msrp: isChecked ? selectedProduct.msrpP1M : selectedProduct.msrpP1Y,
					totalSeats:
						(isChecked ? selectedProduct.msrpP1M : selectedProduct.msrpP1Y) * selectedRow.assignedSeats,
					matchedPkID: isChecked ? selectedProduct.pk_msrpP1M : selectedProduct.pk_msrpP1Y,
					matchedUnitPrice: isChecked ? selectedProduct.unit_priceP1M : selectedProduct.unit_priceP1Y,
					matchedUnitPriceInUsd: isChecked
						? selectedProduct.unit_price_in_usdP1M
						: selectedProduct.unit_price_in_usdP1Y,
					isMonthlyP1M: isChecked, // Update the checkbox state in the row
				};
			}

			return updatedRows;
		});
	};

	useEffect(() => {
		console.log('Rows after state update:', rows);
	}, [rows]);

	const handleCurrencyChange = selectedOption => {
		const newCurrency = selectedOption.value;
		setCurrency(newCurrency);
	};

	const toggleEditMode = index => {
		setRows(prevRows => {
			const updatedRows = [...prevRows];
			updatedRows[index].isEditing = !updatedRows[index].isEditing;
			return updatedRows;
		});
	};

	const handleRowChange = (index, field, value) => {
		const updatedRows = [...rows];

		if (field === 'product') {
			const selectedProduct = products.find(p => p.value === value);

			if (selectedProduct) {
				// Set default pricing to P1Y|monthly on initial product selection
				updatedRows[index] = {
					...updatedRows[index],
					product: selectedProduct.label,
					msrp: selectedProduct.msrpP1Y, // Default to P1Y pricing
					totalSeats: selectedProduct.msrpP1Y * updatedRows[index].assignedSeats,
					matchedPkID: selectedProduct.pk_msrpP1Y,
					matchedUnitPrice: selectedProduct.unit_priceP1Y,
					matchedUnitPriceInUsd: selectedProduct.unit_price_in_usdP1Y,
					isMonthlyP1M: false, // Start with P1Y pricing as default
					isEditing: false,
				};
			}
		} else if (field === 'assignedSeats') {
			updatedRows[index][field] = value;
			updatedRows[index].totalSeats = updatedRows[index].msrp * value;
		}

		setRows(updatedRows);
	};

	const handlePDFPreview = async () => {
		const formData = {
			salesOrderId: activeSalesOrderNceId,
			termMonths: term,
			validThrough:
				offerValidThrough instanceof Date && !isNaN(offerValidThrough) ? offerValidThrough : 'Invalid Date',
			servicesStartDate:
				rows.length > 0 && rows[0].effectiveStartDate instanceof Date && !isNaN(rows[0].effectiveStartDate)
					? rows[0].effectiveStartDate
					: defaultServiceStartDate,
			servicesEndDate:
				defaultServiceEndDate instanceof Date && !isNaN(defaultServiceEndDate)
					? defaultServiceEndDate
					: 'Invalid Date',
			netTerms,
			billingFrequency,
			salesPersonName: senderName,
			companyName: companyName,
			companyAddress,
			companyCity,
			companyState,
			companyZIP: companyZip,
			companyCountry,
			currency,
			platinumSupport,
			status,
		};

		const addons = rows.map(row => {
			return {
				name: row.product || 'N/A',
				assignedSeats: Number(row.assignedSeats) || 0,
				tttPrice: row.msrp || 0,
				tttTotal: row.totalSeats || 0,
				currency: currency || 'USD',
				matchedSkuTitle: row.product || 'N/A',
				matchedPkID: row.matchedPkID || 'N/A', // Ensure matchedPkID is included
				matchedUnitPrice: row.matchedUnitPrice || 0, // Ensure matchedUnitPrice is included
				matchedUnitPriceInUsd: row.matchedUnitPriceInUsd || 0, // Ensure matchedUnitPriceInUsd is included
				effectiveStartDate:
					row.effectiveStartDate instanceof Date && !isNaN(row.effectiveStartDate)
						? row.effectiveStartDate.toISOString()
						: 'Invalid Date',
			};
		});
		console.log(addons);

		let isUploaded = false;
		try {
			isUploaded = await uploadSalesOrderNCEtoCosmos(
				addons,
				formData,
				user?.userName,
				serverAddress,
				accessToken,
				activeSalesOrderNceId
			);
			queryClient.invalidateQueries({
				queryKey: ['listSalespersonsSalesOrdersNce'],
			});
		} catch (error) {
			console.error('Error uploading sales order to Cosmos DB:', error.response?.data || error.message);
		}

		if (isUploaded === true) {
			const blob = await pdf(
				<SalesOrderPDFBasic formData={formData} addons={addons} platinumSupport={platinumSupport} />
			).toBlob();
			const url = URL.createObjectURL(blob);
			window.open(url + '#toolbar=0', '_blank');
		}
	};

	const handleDownloadPDF = async () => {
		const formData = {
			salesOrderId: activeSalesOrderNceId,
			termMonths: term,
			validThrough:
				offerValidThrough instanceof Date && !isNaN(offerValidThrough) ? offerValidThrough : 'Invalid Date',
			servicesStartDate:
				rows.length > 0 && rows[0].effectiveStartDate instanceof Date && !isNaN(rows[0].effectiveStartDate)
					? rows[0].effectiveStartDate
					: defaultServiceStartDate,
			servicesEndDate:
				defaultServiceEndDate instanceof Date && !isNaN(defaultServiceEndDate)
					? defaultServiceEndDate
					: 'Invalid Date',
			netTerms,
			billingFrequency,
			salesPersonName: senderName,
			companyName: companyName,
			companyAddress,
			companyCity,
			companyState,
			companyZIP: companyZip,
			companyCountry,
			currency,
			platinumSupport,
			status,
		};

		const addons = rows.map(row => {
			const selectedProduct = products.find(product => product.label === row.product);
			const matchedVariant = selectedProduct ? selectedProduct.pk_msrp_variants?.[0] : null;

			return {
				name: row.product || 'N/A',
				assignedSeats: Number(row.assignedSeats) || 0,
				tttPrice: row.msrp || 0,
				tttTotal: row.totalSeats || 0,
				currency: row.currency || 'USD',
				// Ensure we're pulling the correct matched fields
				matchedSkuTitle: selectedProduct?.label || 'N/A',
				matchedPkID: matchedVariant?.pk_msrp || 'N/A',
				matchedUnitPrice: matchedVariant?.unit_price || 0,
				matchedUnitPriceInUsd: matchedVariant?.unit_price_in_usd || 0,
				effectiveStartDate:
					row.effectiveStartDate instanceof Date && !isNaN(row.effectiveStartDate)
						? row.effectiveStartDate.toLocaleDateString('en-US')
						: 'Invalid Date',
			};
		});

		console.log(formData);
		console.log(addons);

		let isUploaded = false;
		try {
			isUploaded = await uploadSalesOrderNCEtoCosmos(
				addons,
				formData,
				user?.userName,
				serverAddress,
				accessToken,
				activeSalesOrderNceId
			);
			queryClient.invalidateQueries({
				queryKey: ['listSalespersonsSalesOrdersNce'],
			});
			console.log('Successfully uploaded sales order to Cosmos DB');
		} catch (error) {
			console.error('Error uploading sales order to Cosmos DB:', error.response?.data || error.message);
		}

		if (isUploaded === true) {
			const blob = await pdf(
				<SalesOrderPDFBasic formData={formData} addons={addons} platinumSupport={platinumSupport} />
			).toBlob();

			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = `SalesOrder_NCE_${companyName || 'Unknown'}.pdf`;
			link.click();

			URL.revokeObjectURL(link.href);
		}
	};

	const handleAddRow = () => {
		const newRow = {
			id: uuidv4(),
			product: null,
			assignedSeats: 0,
			totalSeats: 0,
			msrp: 0,
			effectiveStartDate: defaultServiceStartDate || new Date(),
		};
		setRows([...rows, newRow]);
	};

	return (
		<div className="min-h-screen text-white">
			{/* Tab Navigation */}
			<div className="my-6 flex justify-start gap-3 text-black dark:text-white mb-3 bg-gray-500/10 rounded-t">
				<h2
					onClick={() => setView('info')}
					className={`p-3 cursor-pointer text-md ${view === 'info' ? 'border-b-2 border-blue-500 text-blue-500' : ''}`}
				>
					Info Section
				</h2>
				<h2
					onClick={() => setView('product')}
					className={`p-3 cursor-pointer text-md ${view === 'product' ? 'border-b-2 border-blue-500 text-blue-500' : ''}`}
				>
					Product Table
				</h2>
			</div>

			{/* Info Section */}
			{view === 'info' && (
				<div className="flex flex-col space-y-4 md:flex-row md:space-x-6 md:space-y-0">
					<div className="w-1/3">
						<h3 className="text-lg font-bold text-gray-700 dark:text-gray-300">
							Sales Order - Acquisition
						</h3>
						<p className="m-0 text-sm text-slate-600 dark:text-slate-400">
							Enter the details for Client Infomation and switch tabs to Product Table to build Sales
							Order. To create Sales Orders from existing Consumption Report, select report from My
							Documents page.
						</p>
					</div>

					<div className="rounded-xl bg-white p-4 shadow-md md:w-2/3 dark:bg-slate-700">
						<div className="mb-4">
							<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
								Status
							</label>
							<select
								className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
								value={status}
								onChange={e => setStatus(e.target.value)}
							>
								<option value="Draft">Draft</option>
								<option value="Pending">Pending</option>
								<option value="Approved">Approved</option>
								<option value="Rejected">Rejected</option>
							</select>
						</div>

						<div className="grid grid-cols-1 gap-6 md:grid-cols-2">
							<div className="md:col-span-2 grid grid-cols-1 md:grid-cols-3 gap-4">
								<div>
									<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
										Company
									</label>
									<input
										className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
										type="text"
										value={companyName}
										onChange={e => setCompanyName(e.target.value)}
										required
									/>
								</div>

								<div>
									<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
										Sender Name
									</label>
									<input
										className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
										type="text"
										value={senderName}
										onChange={e => setSenderName(e.target.value)}
										required
									/>
								</div>

								<div>
									<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
										Quote Number
									</label>
									<input
										className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
										type="text"
										value={quoteNumber}
										onChange={e => setQuoteNumber(e.target.value)}
										required
									/>
								</div>
							</div>

							<div className="md:col-span-1">
								<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
									Term (Months)
								</label>
								<input
									className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
									type="number"
									value={term}
									onChange={e => setTerm(e.target.value)}
									required
								/>
							</div>

							<div>
								<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
									Valid Through
								</label>
								<DatePicker
									selected={offerValidThrough}
									onChange={date => setOfferValidThrough(date)}
									className="w-full p-2 rounded-lg border text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
								/>
							</div>

							<div>
								<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
									Service Start Date
								</label>
								<DatePicker
									selected={defaultServiceStartDate}
									onChange={date => setDefaultServiceStartDate(date)}
									className="w-full p-2 rounded-lg border text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
								/>
							</div>

							<div>
								<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
									Service End Date
								</label>
								<DatePicker
									selected={defaultServiceEndDate}
									onChange={date => setDefaultServiceEndDate(date)}
									className="w-full p-2 rounded-lg border text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
								/>
							</div>

							<div>
								<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
									Net Terms
								</label>
								<select
									className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
									value={netTerms}
									onChange={e => setNetTerms(e.target.value)}
								>
									{netTermsOptions.map(option => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</select>
							</div>

							<div>
								<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
									Billing Frequency
								</label>
								<select
									className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
									value={billingFrequency}
									onChange={e => setBillingFrequency(e.target.value)}
								>
									{billingFrequencyOptions.map(option => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</select>
							</div>

							<div>
								<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
									Platinum Support
								</label>
								<select
									className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
									value={platinumSupport}
									onChange={e => setPlatinumSupport(e.target.value)}
								>
									{platSupportOptions.map(option => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</select>
							</div>

							<div>
								<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
									Company Address
								</label>
								<input
									className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
									type="text"
									value={companyAddress}
									onChange={e => setCompanyAddress(e.target.value)}
									required
								/>
							</div>

							<div>
								<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
									City
								</label>
								<input
									className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
									type="text"
									value={companyCity}
									onChange={e => setCompanyCity(e.target.value)}
									required
								/>
							</div>

							<div>
								<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
									State/Province/Region
								</label>
								<input
									className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
									type="text"
									value={companyState}
									onChange={e => setCompanyState(e.target.value)}
									required
								/>
							</div>

							<div>
								<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
									ZIP/Postal Code
								</label>
								<input
									className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
									type="text"
									value={companyZip}
									onChange={e => setCompanyZip(e.target.value)}
									required
								/>
							</div>

							<div>
								<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
									Country
								</label>
								<input
									className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
									type="text"
									value={companyCountry}
									onChange={e => setCompanyCountry(e.target.value)}
									required
								/>
							</div>
						</div>
					</div>
				</div>
			)}
			{/* Product Table */}
			{view === 'product' && (
				<div className="relative flex flex-col items-center w-full">
					<div className="flex justify-between mb-4 w-full">
						<div className="flex items-center space-x-4 bg-gray-200 dark:bg-gray-700 rounded-lg p-2 shadow-md">
							{/* Add Currency Dropdown */}
							<div className="flex items-center space-x-2">
								<label htmlFor="currency-select" className="text-black dark:text-white">
									Currency:
								</label>
								<Select
									id="currency-select"
									value={currencyOptions.find(option => option.value === currency)}
									onChange={handleCurrencyChange}
									options={currencyOptions}
									placeholder="Select Currency"
									className="text-black w-36"
								/>
							</div>

							{/* Control Buttons */}
							<button
								className="control-button text-white transform transition-transform duration-300 ease-in-out"
								onClick={handleAddRow}
							>
								<ImPlus className="text-xl rotate-icon text-black dark:text-white" />
								<div className="tooltip2">Add Row</div>
							</button>
							<button
								className="control-button text-white transform transition-transform duration-300 ease-in-out"
								onClick={handlePDFPreview}
							>
								<ImSearch className="text-xl rotate-icon text-black dark:text-white" />
								<div className="tooltip2">Preview</div>
							</button>
							<button
								className="control-button text-white transform transition-transform duration-300 ease-in-out"
								onClick={handleDownloadPDF}
							>
								<ImDownload className="text-xl rotate-icon text-black dark:text-white" />
								<div className="tooltip2">Download</div>
							</button>
						</div>
					</div>

					<table className="min-w-full table-outline text-sm text-black dark:bg-gray-700 dark:text-white">
						<thead className="bg-gray-200 dark:bg-gray-700">
							<tr>
								<th
									className="header-product p-2 text-black dark:bg-gray-700 dark:text-white"
									style={{ width: '40%' }}
								>
									Product
								</th>
								<th
									className="header-product p-2 text-black dark:bg-gray-700 dark:text-white"
									style={{ width: '10%' }}
								>
									Assigned Seats
								</th>
								<th
									className="header-product p-2 text-black dark:bg-gray-700 dark:text-white"
									style={{ width: '10%' }}
								>
									Trusted Tech Team ({currencySymbols[currency]}/Seat)
								</th>
								<th
									className="header-product p-2 text-black dark:bg-gray-700 dark:text-white"
									style={{ width: '10%' }}
								>
									Trusted Tech Team (Total {currencySymbols[currency]})
								</th>
								<th
									className="header-product p-2 text-black dark:bg-gray-700 dark:text-white"
									style={{ width: '10%' }}
								>
									Effective Start Date
								</th>
								<th
									className="header-product p-2 text-black dark:bg-gray-700 dark:text-white"
									style={{ width: '5%' }}
								>
									Monthly (P1M)
								</th>
								<th
									className="header-product p-2 text-black dark:bg-gray-700 dark:text-white"
									style={{ width: '10%' }}
								>
									Actions
								</th>
							</tr>
						</thead>
						<tbody className="bg-white dark:bg-gray-700">
							{rows.map((row, index) => (
								<tr key={row.id}>
									<td className="border px-4 py-2">
										{row.product && !row.isEditing ? (
											<div className="flex items-center">
												<span>{row.product}</span>
												<button
													onClick={() => toggleEditMode(index)}
													className="ml-2 text-blue-500 hover:text-blue-700"
													title="Edit Product"
												>
													Edit
												</button>
											</div>
										) : (
											<Select
												value={row.product ? { label: row.product, value: row.product } : null}
												onChange={selectedOption =>
													handleRowChange(
														index,
														'product',
														selectedOption ? selectedOption.value : ''
													)
												}
												options={products}
												placeholder="Select a product"
												isClearable
												className="text-black"
												menuPortalTarget={document.body}
												styles={{
													menuPortal: base => ({
														...base,
														zIndex: 9999,
													}),
												}}
											/>
										)}
									</td>

									<td className="border p-2">
										<input
											type="number"
											className="w-full text-black"
											value={row.assignedSeats || 0}
											onChange={e => handleRowChange(index, 'assignedSeats', e.target.value)}
										/>
									</td>
									<td className="border p-2">
										{currencySymbols[currency]}
										{formatMoney(row.msrp || 0)} {/* Auto-populated based on product selection */}
									</td>
									<td className="border p-2">
										{currencySymbols[currency]}
										{formatMoney(row.totalSeats || 0)} {/* Trusted Tech Team Total */}
									</td>
									<td className="border p-2">
										<DatePicker
											selected={row.effectiveStartDate || new Date()}
											onChange={date => handleRowChange(index, 'effectiveStartDate', date)}
											className="w-full p-2 rounded-lg border text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
										/>
									</td>
									<td className="border p-2 text-center">
										<input
											type="checkbox"
											checked={row.isMonthlyP1M || false}
											onChange={e => handleP1MCheckboxToggle(index, e.target.checked)}
										/>
									</td>
									<td className="border p-2 bg-gray-200 dark:bg-gray-700 text-center">
										{' '}
										<button
											onClick={() => handleDeleteRow(index)}
											className="text-red-600 hover:text-red-800"
											title="Delete Row"
										>
											&#10060;{' '}
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

export default SOrderNCE;
