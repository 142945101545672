import { useAuth } from '../../../context/AuthContext';
import Setting from '../Setting';
import SettingsGroup from '../SettingsGroup';

const GeneralSettings = () => {
	const { user } = useAuth();

	const rolesPriority = ['admin', 'operations', 'finmanager', 'finance'];
	// .find() linearly indexes [0..], so we can determine order of permissions
	const highestPermissionRole = user?.userRoles?.find(role => rolesPriority.includes(role)) || 'unknown';

	return (
		<div className="space-y-3 w-full">
			<SettingsGroup
				settingsKey="general"
				settingsSubGroup="fxRates"
				settingsSchema={{ fxRates: {} }}
				title="FX Rates"
				subTitle="Manage the foreign exchange rates for different currencies."
				accessGranted={['operations']}
			>
				{({ isEditable, handleInputChange, localSettings, settingsSubGroup }) => (
					<div className="space-y-3">
						<div className="grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
							<Setting
								label="🇬🇧 GBP"
								name="GBPRate"
								type="number"
								placeholder="Enter value"
								localSettings={localSettings}
								settingsSubGroup={settingsSubGroup}
								handleInputChange={handleInputChange}
								disabled={!isEditable}
							/>
							<Setting
								label="🇪🇺 EUR"
								name="EURRate"
								type="number"
								placeholder="Enter value"
								localSettings={localSettings}
								settingsSubGroup={settingsSubGroup}
								handleInputChange={handleInputChange}
								disabled={!isEditable}
							/>
							<Setting
								label="🇨🇦 CAD"
								name="CADRate"
								type="number"
								placeholder="Enter value"
								localSettings={localSettings}
								settingsSubGroup={settingsSubGroup}
								handleInputChange={handleInputChange}
								disabled={!isEditable}
							/>
						</div>
					</div>
				)}
			</SettingsGroup>
			{highestPermissionRole === 'finance' || highestPermissionRole === 'finmanager' ? (
				<></>
			) : (
				<>
					<SettingsGroup
						settingsKey="general"
						settingsSubGroup="accessRestrictions"
						settingsSchema={{ accessRestrictions: {} }}
						title="Access Restrictions"
						subTitle="Manage the access restrictions for all users."
						alwaysEditable={true}
					>
						{({ isEditable, handleInputChange, localSettings, settingsSubGroup }) => (
							<div className="space-y-3">
								<div className="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
									<Setting
										label="Consumption Report Generation"
										name="consumptionReportGeneration"
										type="checkbox"
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
										disabled={!isEditable}
									/>
									<Setting
										label="Sales Order Generation"
										name="salesOrderGeneration"
										type="checkbox"
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
										disabled={!isEditable}
									/>
								</div>
							</div>
						)}
					</SettingsGroup>
					<SettingsGroup
						settingsKey="general"
						settingsSubGroup="alertBanner"
						settingsSchema={{ alertBanner: {} }}
						title="Alert Banner Settings"
						subTitle="Manage the alert banner display and messages."
						alwaysEditable={true}
					>
						{({ isEditable, handleInputChange, localSettings, settingsSubGroup }) => (
							<div className="space-y-3">
								<div className="grid grid-cols-1 gap-4">
									<Setting
										label="Show Alert Banner"
										name="showAlertBanner"
										type="checkbox"
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
										disabled={!isEditable}
									/>
									<Setting
										label="Expiration Date"
										name="expirationDate"
										type="datetime-local"
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
										disabled={!isEditable}
									/>
									<Setting
										label="Title"
										name="alertTitle"
										type="text"
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
										disabled={!isEditable}
									/>
									<Setting
										label="Message"
										name="alertMessage"
										type="textarea"
										placeholder="Enter value"
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
										disabled={!isEditable}
									/>
								</div>
							</div>
						)}
					</SettingsGroup>
					<SettingsGroup
						settingsKey="general"
						settingsSubGroup="announcements"
						settingsSchema={{ announcements: {} }}
						title="Announcements"
						subTitle="Manage announcements dislayed on the home page."
						alwaysEditable={true}
					>
						{({ isEditable, handleInputChange, localSettings, settingsSubGroup }) => (
							<div className="space-y-3">
								<div className="grid grid-cols-1 gap-4">
									<Setting
										label="Show Announcements"
										name="showAnnouncements"
										type="checkbox"
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
										disabled={!isEditable}
									/>
									<Setting
										label="Message"
										name="announcementMessage"
										type="textarea"
										localSettings={localSettings}
										settingsSubGroup={settingsSubGroup}
										handleInputChange={handleInputChange}
										disabled={!isEditable}
									/>
								</div>
							</div>
						)}
					</SettingsGroup>
				</>
			)}
		</div>
	);
};

export default GeneralSettings;
