import { createContext, useState, useContext } from 'react';
import { setRedisKeyValue } from '../utils';
import { useAuth } from './AuthContext';
import axios from 'axios';
import { serverAddress } from '../authConfig';

// Create a context for settings
const SettingsContext = createContext();

// Create a provider component
export const SettingsProvider = ({ children }) => {
	const { accessToken, user } = useAuth();

	// Initialize the settings state
	const [settings, setSettings] = useState(null);
	const [settingsLoading, setSettingsLoading] = useState(true);

	const getRedisKeyValue = async key => {
		if (!accessToken) return;

		try {
			const response = await axios.get(`${serverAddress}/admin?key=${key}`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': `${user?.userName}`,
				},
			});
			return response;
		} catch (error) {
			console.error('Error fetching key from Redis:', error);
			return error;
		}
	};

	// Fetch settings from Redis when the component mounts
	const initSettings = async () => {
		try {
			const response = await getRedisKeyValue('admin-settings', accessToken, user?.userName);
			if (response.data) {
				setSettings(response.data);
			}
		} catch (error) {
			console.error('Error fetching settings from Redis:', error);
		} finally {
			setSettingsLoading(false);
		}
	};

	// Update a specific setting
	const updateSetting = async (category, value) => {
		// Create the updated settings inside the async function
		const updatedSettings = prevSettings => ({
			...prevSettings,
			[category]: value,
		});

		// Update the settings state first
		setSettings(prevSettings => updatedSettings(prevSettings));

		try {
			// Save the updated settings to Redis asynchronously
			const response = await setRedisKeyValue(
				'admin-settings',
				JSON.stringify(updatedSettings(settings)), // assuming `settings` is accessible or use prev settings directly
				accessToken,
				user?.userName
			);

			return response; // Return the status of the save operation
		} catch (error) {
			console.error('Failed to update settings in Redis:', error);
			return false; // Indicate failure
		}
	};

	return (
		<SettingsContext.Provider value={{ settings, updateSetting, initSettings, settingsLoading }}>
			{children}
		</SettingsContext.Provider>
	);
};

// Custom hook to access the settings context
export const useSettings = () => useContext(SettingsContext);
