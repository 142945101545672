import { useNavigate, useParams } from 'react-router-dom';
import PlatinumCalculatorSettings from '../components/settings/subGroups/PlatinumCalculatorSettings';
import SalesOrderSettings from '../components/settings/subGroups/SalesOrderSettings';
import CommissionsSettings from '../components/settings/subGroups/CommissionsSettings';
import GeneralSettings from '../components/settings/subGroups/GeneralSettings';
import ConsumptionReportSettings from '../components/settings/subGroups/ConsumptionReportSettings';

// Menu items configuration
const menuItems = [
	{ label: 'General', key: 'general' },
	{ label: 'Platinum Calculator', key: 'platinum-calculator' },
];

// Permissions for each settings group
export const settingsPermissionsMatrix = {
	general: {
		fxRates: ['operations', 'admin'],
		accessRestrictions: ['operations', 'admin'],
		alertBanner: ['operations', 'admin'],
		announcements: ['operations', 'admin'],
	},
	platinumCalculator: {
		cost: ['operations', 'admin'],
		limits: ['operations', 'admin'],
		profitabilityThresholds: ['operations', 'admin'],
	},
};

// Component mapping for each menu item
const componentsMap = {
	general: props => <GeneralSettings {...props} />,
	commissions: props => <CommissionsSettings {...props} />,
	consumptionReport: props => <ConsumptionReportSettings {...props} />,
	salesOrder: props => <SalesOrderSettings {...props} />,
	'platinum-calculator': props => <PlatinumCalculatorSettings {...props} />, // Match key properly here
};

const Settings = ({ serverAddress, accessToken, userName }) => {
	const navigate = useNavigate();
	const { menu } = useParams(); // Get the menu name from the URL
	const selectedMenu = menu || 'general'; // Default to 'general' if no menu is provided

	// Function to handle menu clicks and update the URL
	const handleMenuClick = key => {
		navigate(`/settings/${key}`); // Navigate to the new route
	};

	// Check if the selected menu has a matching component
	const renderComponent = componentsMap[selectedMenu] ? componentsMap[selectedMenu] : componentsMap['general'];

	console.info(selectedMenu);

	return (
		<div className="h-screen">
			{/* Submenu */}
			<div className="text-black dark:text-white mb-3 bg-gray-500/10 rounded">
				<ul className="flex justify-start gap-3" role="tablist">
					{menuItems.map(item => (
						<li
							key={item.key}
							role="tab"
							aria-selected={selectedMenu === item.key}
							className={`p-3 cursor-pointer flex items-center transition-colors duration-200 ${
								selectedMenu === item.key
									? 'border-b-2 border-blue-500 text-blue-500'
									: 'hover:text-blue-500'
							}`}
							onClick={() => handleMenuClick(item.key)}
						>
							{item.label}
						</li>
					))}
				</ul>
			</div>

			{/* Content Area */}
			<div>
				{renderComponent({
					serverAddress,
					accessToken,
					userName,
				})}
			</div>
		</div>
	);
};

export default Settings;
